.header{
  height: auto;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  background-color: $background-header-gradient100;

  &__buttonsblock{
    display: none;
  }

  &__bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    font-family: Arial, Helvetica, sans-serif;

    &-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
  }

  &__panel{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
    position: relative;
    width: 95%;
    margin: 0 auto;
    z-index: 5;
  }

  &__buttons{
    width: 90%;
    max-width: 450px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 0 0 35px;

    &-block{
      height: 50px;
      width: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 45px;
      margin-top: 10px;
      -webkit-transition:opacity 0.3s ease;
      transition:opacity 0.3s ease;

        &-icon{
          width: 70px;
          font-size: 12px;
          text-align: center;
          color: #fff;
          line-height: 1;
          background: none;
          border: none;
          font-weight: normal;
          padding: 0;

          & span{
            position: absolute;
            right: 6px;
            bottom: 6px;
            width: 25px;
            height: 25px;
            background-color: #cfcfcf;
            border: 1px #cfcfcf solid;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            color: #333;
            line-height: 0.5;
          }
        }


    }
  }

}

@media (min-width: $desktop) {
  .header {

    &__bar{
      max-width: 950px;
    }

    &__buttonsblock{
      display: block;
      padding-top: 20px;
    }

    &__buttons {
      width: 410px;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 0 0 0 15px;

      &-block {
        width: 70px;
        padding-top: 0;

        & a {
          width: 70px;
        }
      }
    }

    &__panel{
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      height: 100px;
      padding-bottom: 0;
    }

    &__panel{
      min-width: 1150px;
      max-width: 1360px;
    }
    &__buttons-block:hover {
      opacity: 0.8;
    }

  }
}

@media (min-width: 1900px) {
  .header{
    padding-top: 10px;

    &__buttons-block:hover{
      opacity: 0.8;
    }

    &__panel{
      max-width: 1687px;
    }

  }
}