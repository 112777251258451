.page{
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  padding-top: 10px;

  &__wrapper{
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: block;

    &:before {
      position: absolute;
      top: 0;
      left:0;
      right:0;
      margin: 0 auto;
      content: '';
      height: 100px;
      width: 100%;
      display: block;
      background-color: $background-content;
    }

    &-back {
      background-color: $background-content;
      min-height: calc(100vh - 250px);
      width: 100%;
      display: block;
      position: relative;
      top: 100px;

    }

    &-block{
      min-height: calc(100vh - 250px);
      position: relative;
      top: -70px;
    }

    & p{
      width: 90%;
      font-size: 14px;
      color: #333;
      font-family: "Open Sans", sans-serif;
      line-height: 1.5;
      margin: 0 auto 15px;
      text-align: justify;
      text-indent: 20px;

      & a{
        color: #333;
        text-decoration: none;
      }

      & i{
        float: right;
        font-size: 16px;
      }
    }

    & ul,ol{
      width: 90%;
      font-size: 14px;
      color: #333;
      font-family: "Open Sans", sans-serif;
      line-height: 1.5;
      margin: 0 auto 15px;
      text-align: justify;

      & li{
        margin: 15px 0 0 15px;
        line-height: 1.5;
        position: relative;

        & li{
          line-height: 1.5;
          text-align: justify;
          margin: 10px 0 10px 30px !important;
          position: relative;
        }
      }
    }


    & ul{
      list-style: disc !important;
    }

    & ol {
        list-style: none !important;
         counter-reset: li;

        & li:before {
          counter-increment: li;
           content: counters(li, ".") ". ";
          position: absolute;
          left: -15px;
        }

        & ol li:before {
          counter-increment: li;
          content: counters(li, ".") ". ";
          position: absolute;
          left: -30px;
        }
      }

  }

  &__title{
    font-size: 26px;
    font-family: "Open Sans", sans-serif;
    color: #333;
    padding: 20px 0;
    text-align: center;
    line-height: 1.5;
    font-weight: normal;
  }

  &__h2{
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    color: #333;
    padding: 5px 0;
    text-align: center;
    line-height: 1.5;
    font-weight: normal;
  }

  &__category {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: block;

    &:before {
      position: absolute;
      top: 0;
      left:0;
      right:0;
      margin: 0 auto;
      content: '';
      height: 100px;
      width: 100%;
      display: block;
      background-color: $background-content-category;
    }

    &-back {
      background-color: $background-content-category;
      height: 100%;
      width: 100%;
      display: block;
      position: relative;
      top: 100px;
    }

    &__wrapper{
      position: relative;
      top: -70px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-side{
      z-index: 2;

      &-title{
        font-size: 20px;
        font-family: "Open Sans", sans-serif;
        color: $color-page-side-title;
        text-align: center;
        width: 240px;
        margin: 9px auto 9px;
      }
    }


    &-content{
      width: 95%;
      margin: 0 auto;

      &-title{
        font-size: 20px;
        font-family: "Open Sans", sans-serif;
        color: $color-page-side-text;
        margin: 20px 0;
        text-align: center;
      }

      &-absent{
        color: $color-page-side-text;
      }

      &-filter{
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        color: $color-page-side-text;
        height: auto;
        width: 100%;
        border-bottom: 1px #e4e4e4 solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & a{
          color: #333;
          margin: 10px 0;
        }

        & .sort{
          margin: 10px 0;

          & select{
            border-bottom: 1px #e4e4e4 solid;
            background: none;
            color: $color-page-side-text;
            border-left: none;
            border-right: none;
            border-top: none;
            width: 185px;
            appearance: none !important;
            /*for WebKit*/
            -webkit-appearance: none;
            /* for FF */
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
            /* for IE */
            -ms-appearance: none;
            appearance: none!important;
            background-image: url('../images/select-arrow.png');
            background-size: 11px;
            background-position: right center;
            background-repeat: no-repeat;

            & option{
              background-color: #fff;
              color: $color-page-side-text;
            }
          }

        }

        & .samount{
          margin: 10px 0;

          & select{
            width: 40px;
            border-bottom: 1px #e4e4e4 solid;
            background: none;
            color: $color-page-side-text;
            border-left: none;
            border-right: none;
            border-top: none;
            appearance: none !important;
            /*for WebKit*/
            -webkit-appearance: none;
            /* for FF */
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
            /* for IE */
            -ms-appearance: none;
            appearance: none!important;
            background-image: url('../images/select-arrow.png');
            background-size: 11px;
            background-position: right center;
            background-repeat: no-repeat;

            & option{
              background-color: #fff;
              color: $color-page-side-text;
            }
          }
        }

      }

      &-product{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 30px 0;
      }

      &-showing{
        display: block;
        width: 100%;
        text-align: right;
        font-size: 14px;
        color: #333;
        margin: 0 0 30px 0;
      }

      &-description{

        & h2{
          font-size: 16px !important;
          color: #595959 !important;
          text-indent: 0 !important;
          margin: 15px 0 5px;
        }

        & h3{
          font-size: 15px !important;
          color: #595959 !important;
          text-indent: 0 !important;
          margin: 15px 0 5px;
        }

        & p,ul,ol{
          font-size: 14px;
          color: #858585;
          line-height: 1.5;
          margin-bottom: 15px;

          & li{
            margin-left: 35px;
          }
        }

        & p{
          text-align: justify;
          text-indent: 20px;
        }

        & ul{
          list-style: disc !important;
        }

        & ol{
          list-style: decimal !important;
        }

        & b{
          font-weight: bold;
        }

      }
    }

    &-special{
      width: 100%;
      margin: 0;
    }
  }

  &__product {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: block;

    &:before {
      position: absolute;
      top: 0;
      left:0;
      right:0;
      margin: 0 auto;
      content: '';
      height: 100px;
      width: 100%;
      display: block;
      background-color: $background-content-product;

    }

    &-back {
      background-color: $background-content-product;
      height: 100%;
      width: 100%;
      position: relative;
      top: 70px;
      -webkit-border-radius: 0 0 8px 8px;
      -moz-border-radius: 0 0 8px 8px;
      border-radius: 0 0 8px 8px;
      padding: 0 20px;
      display: block;
    }

    &__wrapper{
      position: relative;
      top: -85px;

      &-colums{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-modify{
        top: 0px;
      }
    }
  }
}

@media (min-width: $phone-landscape) {
  .page {

    &__title {
      font-size: 25px;
      padding: 20px;
      text-align: left;
    }

    &__h2 {
      font-size: 20px;
      padding: 10px 0 10px 60px;
      text-align: left;
    }
    &__product{
      &__wrapper {
        &-modify {
          top: -20px;
        }
      }
    }
  }
}

@media (min-width: 496px) {
  .page {
    &__category{
      &-content{
        &-product{
          justify-content: space-around;
        }
      }
    }
  }
}

@media (min-width: $tablet-portrait) {
.page{

    &__wrapper{
      & p{
        width: 100%;
        margin: 0 0 15px;
        padding: 0 80px;
      }

      & ul,ol{
        width: 100%;
        margin: 15px 0;
        padding: 0 80px;
        text-align: justify;

        & li{
          margin: 15px 0 0 55px !important;

          & li{
            margin: 10px 0 10px 55px !important;
          }
        }

        & ol{
          padding: 0 !important;

        }
      }

    }

  &__category{

    &-content{

      &-title{
        margin: 20px 0 20px 30px;
        text-align: left;
      }

      &-filter {
          height: 40px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
        }
      }
  }
  &__product {

    &__wrapper{
      &-modify{
        top: -40px;
      }
    }
    &-back {
      top: 100px;
    }
  }
}
}

@media (min-width: 900px) {
  .page {

    &__product {

      &__wrapper {
        &-colums {
          flex-direction: row;
          justify-content: space-between;
        }
        &-modify{
          top: -60px;
        }
      }
    }
  }
}

@media (min-width: $tablet-landscape) {
  .page {

    &__wrapper{
      & p{
        & a:hover{
          text-decoration: underline;
        }
      }
    }

    &__category {

      &-content {

        &-title {
          font-size: 25px;
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .page{

    &__wrapper{
      width: 95%;
      max-width: 1360px;
    }


    &__category {
      width: 95%;
      max-width: 1360px;

      &__wrapper{
        top: -70px;
      }

      &-side{
        width: 240px;
        display: block;
      }

      &-content{
        margin: 0  0 0 30px;

        &-title {
          font-size: 30px;
        }

        &-filter{
          height: 40px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          & a{
            
            &:hover{
              text-decoration: underline;
            }
          }

        }

        &-product{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: 30px;
        }
      }

      &-special{
        width: 100%;
        margin: 0;
      }
    }

    &__product {
      width: 95%;
      max-width: 1360px;
      padding: 0;

      &-back{
        padding: 0;
      }

      &__wrapper {
        &-colums {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        &-modify{
          top: -60px;
        }

      }
    }
  }
}

@media (min-width: 1900px) {
  .page{

    &__wrapper{
      width: 95%;
      max-width: 1745px;
    }

    &__category {
      width: 95%;
      max-width: 1725px;

      &__wrapper{
        top: -70px;
      }

      &-side{
        width: 240px;
        display: block;
      }

      &-content{
        width: 1745px;
        margin: 0  0 0 30px;

        &-title {
          font-size: 30px;
        }

        &-filter{
          height: 40px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          & a{

            &:hover{
              text-decoration: underline;
            }
          }

        }

        &-product{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: 30px;
        }


      }

      &-special{
        width: 100%;
        margin: 0;
      }
    }

    &__product {
      width: 95%;
      max-width: 1725px;

      &__wrapper {
        &-colums {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}