.products{
  width: 230px;
  margin: 0 15px 25px 0;
  height: 100%;
  min-height: 516px;

  &:hover{
    .products__option{
      position: relative;
      overflow: visible;
      opacity: 1;
      z-index: 1;
      height: auto;
    }
    .products__wrapper{
      position: absolute;
      z-index: 2;
      box-shadow: 0 5px 6px rgba(0,0,0,.15),0 3px 16px rgba(0,0,0,.07),0 9px 12px rgba(0,0,0,.09);
    }
    .products{
      height: auto;
    }
  }

  &__wrapper{
    width: 230px;
    height: auto;
    background-color: $background-products;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 1px $color-border-products solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    position: relative;
    box-shadow: 0 1px 3px 0 rgba( 0, 0, 0, .2);
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  &__option {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #cfcfcf;
    padding: 0 10px;
    font-size: 14px;
    margin: 0 auto;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: 13px;
    z-index: -1;


    & .hcol-image img {
      display: block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      border-radius: 25px;
      transition: all 0.3s ease;
    }

    .hpm-group{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 5px 10px;

      & .hpm-title{
        width: 100%;
        margin: 8px 0;
        color: #333;
      }

      & .hpm-type-images{
        width: 100%;
        min-height: 23px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;

      }

      .hpm-type-html-select{
        width: 100%;

        .active::after{
          width: 100%;
        }
      }

      & .hpm-item:first-child{

        & .option-name::before{
          content: '';
          display: none;
        }
      }

      & .disabled{
        &::before{
          opacity: 0.5;
        }
        .option-name-item,
        .option-name{
          opacity: 0.5;
        }
      }
    }

    & .hpm-select-item {
      font-size: 13px;
      color: #656565;
      line-height: 1;
      margin-bottom: 5px;
      width: 100%;
      border-bottom: 1px #5e5d5d dashed;
      padding: 5px;
      position: relative;
      display: flex;
      justify-content: left;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:before {
        content: '-';
        display: block;
        margin-right: 5px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 0;
        background-color: #ff0000;
        color: #333;
        -webkit-transition: width .2s ease, left .2s ease;
        transition: width .2s ease, left .2s ease;
        left: 0;
        bottom: -1px;
      }

    }

    & .option-name{
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      color: #333;
      line-height: 0.9;
      cursor: pointer;

      &::before{
        content: '/';
        color: #444444;
        margin-right: 5px;
        font-size: 13px;
      }
      transition: color .3s ease;
      -webkit-transition: color .3s ease;
      -moz-transition: color .3s ease;

      &:hover{
        color: #ff0000;
      }
    }

    & .option-name-item {
      font-size: 13px;
      padding: none;
      justify-content: space-between !important;
      width: 100%;
      align-items: center;

      &::after {
        font-size: 14px;
        height: 14px;
      }
      &::before {
        content: '';
        display: none;
      }
    }

     .active{
      color: #000;

      &-table{
        color: #000 !important;

        &::before {
          content: "\f147" !important;
          font-family: dashicons;
          margin-left: -5px;
        }
        &::after{
          width: 100% !important;
        }
      }

      .hcol-image {
        color: #333;
        display: flex !important;
        flex-direction: row;
        width: 40px !important;
        justify-content: flex-start;
        border: 1px #838383 solid !important;
        padding: 3px;
        margin-right: 5px !important;
        border-radius: 50px;
        height: 23px;

        & img{
          margin-right: -1px;
        }

        &::after {
          content: "\f147" !important;
          font-family: dashicons;
          display: block;
          color: #333;
          font-size: 18px;
          height: 15px;
          line-height: 0.9;
        }
      }

      .option-name,
      .option-name-item{
        color: #333 !important;
        display: flex !important;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        &::after {
          content: "\f147" !important;
          font-family: dashicons;
          color: #333;
          font-size: 22px;
          line-height: 0.9;
          width: 13px;
          display: flex;
          justify-content: center;
          margin-left: 5px;
        }
      }

       .option-name::after{
         height: 19px;
       }
       .option-name-item::after{
         height: 14px;
       }

    }


    &-icon{
      position: absolute;
      left: 5px;
      top: 5px;
      color: #333333;

      & .dashicons{
        font-size: 30px !important;
      }
    }

  }


    & a:first-child{
      margin-left: 0;
    }

  &:last-child{
    margin-right: 0;
  }

  &__optionactive{
    overflow: visible;
    opacity: 1;
    z-index: 1;
  }

  &__str{
    height: 25px;
    width: 100%;
    margin: 4px auto 0;
    position:relative;


    &-wrapper{
      height: 35px;
      width: 100%;
      padding: 9px 10px;
      color: #cfcfcf;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: absolute;
      transition: all 0.2s ease-in-out;
      overflow: visible;
      opacity: 1;
      top: 0;


      .fa-star-o,
      .fa-star{
        font-size: 13px;
      }

      .fa-stack{
        margin-right: 0;
      }
    }

    &-reviews{
      line-height: 0.8;
      & a{
        font-size: 13px;
        color: #333;
        text-decoration: none;
        transition: color 0.3s ease;
        -webkit-transition: color 0.3s ease;

        &:hover{
          color: #ff0000;
        }
      }
    }

    &-stock{
      line-height: 1;
      font-weight: bold;
    }

    &-green{
      color: #00ad00;
    }

    &-red{
      color: #e60418;
    }

    &-yellow{
      color: #e6df00;
    }

    &-green,
    &-yellow,
    &-red {
      font-size: 14px;
      line-height: 1;
    }
  }

  &__image{
    width: 100%;
    height: 270px;
    position: relative;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-element{
      margin: 0 auto;
      width: 100%;
      height: auto;
      max-height: 260px;
      max-width: 260px;
      padding: 10px;

      & img{
        width: auto;
        height: auto;
        max-width: 270px;
        max-height: 270px;
      }
    }

    &-rating{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
    }
  }

  &__info{
    width: 100%;
    min-height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &-title{
      min-height: 76px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 14px;
      color: $color-products-title;
      font-family: ProximaNova, sans-serif;
      line-height: 1.5;
      padding: 0 10px;
      overflow: hidden;
      -webkit-transition: color .3s ease;
      transition: color .3s ease;
      margin: 10px 0;

      &:hover{
        color: $color-products-title-hover;
      }
    }

    &-footer{
      height: 20px;
      width: 100%;
      margin: 15px 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      font-size: 13px;
      color: #878787;
    }

    &-buyblock{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

    }

    &-buttons{
      height: 20px;
      width: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &-basket{
        color: $color-buttons-products-buy;
        font-size: 18px;
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        padding: 0;
        background-color: #000;
        //background: rgb(59,2,2); /* Old browsers */
        //background: -moz-linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(59,2,2,1) 200%); /* FF3.6-15 */
        //background: -webkit-linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(59,2,2,1) 200%); /* Chrome10-25,Safari5.1-6 */
        //background: linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(59,2,2,1) 200%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#3b0202',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        -webkit-border-top-left-radius: 8px;
        -webkit-border-bottom-left-radius: 8px;
        -moz-border-top-left-radius: 8px;
        -moz-border-bottom-left-radius: 8px;
        height: 40px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;

        & i{
          font-style: normal;
          cursor: pointer;
          line-height: 1;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
        }
      }

      &-tir {
        width: 10px;
        height: 20px;
        padding: 13px 0 13px 0;
        background-color: transparent;
        border: none;
        margin: 0 10px;

        span {
          display: block;
          width: 100%;
          height: 1px;
          background-color: #444444;
          -webkit-transition: background-color 0.2s ease;
          transition: background-color 0.2s ease;
        }
      }

    }

    &-price{
      height: 40px;
      max-width: 50%;
      display: flex;
      flex-direction: column;
      text-align: flex-start;
      font-family: "Century Gothic", sans-serif;

      &-symbol{
        font-style: normal;
        font-size: 18px;
        line-height: 1;
        position: relative;
        top: 2px;
        left: 2px;
      }

      &-real{
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 25px;
        line-height: 1.419;
        color: #333;
        padding-left: 10px;
      }

      &-old{
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        font-size: 14px;
        color: #797979;
        text-decoration: line-through;
        float: left;
        padding-left: 10px;

        .products__info-price-symbol{
          font-size: 14px !important;
        }
      }

      &-new{
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        font-size: 18px;
        text-align: right;
        font-weight: bold;
        padding-left: 10px;
        color: #ff0000;
      }

    }
  }
}


@media (min-width: 1165px) {
.products{
  width: 270px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  height: 100%;

  &__wrapper{
    width: 270px;
  }

  &__info {
    width: 270px;

    &-price{
      height: 35px;
      width: 100%;
    }
  }
}

}

@media (min-width: 1320px) {
  .products {
    height: 100%;
    margin-right: 40px;

    &__image{
      height: 270px;

      &-rating {
        bottom: 7px;
      }
    }

    .hpm-group{
      position: relative;

      &:first-child{
        border-top: 1px $color-border-images-second-product solid;
      }

      &-row{
        height: 35px;
      }
      &-list{
        height: auto;
      }
    }

    &__option {
      overflow: hidden;
      opacity: 0;
      height: none;
      -webkit-border-radius: 0 4px 4px 0;
      -moz-border-radius: 0 4px 4px 0;
      border-radius: 4px;
      -webkit-border-top-right-radius: 0;
      -webkit-border-top-left-radius: 0;
      -moz-border-top-right-radius: 0;
      -moz-border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top: none;

      &-title {
        font-size: 14px;
        margin: 2px 0 10px 0;
      }

      &:before {
        content: '';
        color: #444444;
        margin-right: 5px;
        font-size: 16px;
      }


      & .hpm-select-item:hover{
        color: #ff0000;
      &::after {
        width: 100%;
      }
    }

      .hpm-type-html-select{

      }

    }

    &__info{
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-title {
        height: 90px;
      }

      &-buttons{

        &-basket:hover{
          background-color: #ff0000;
        }
      }

      &-price{
        height: 35px;
        width: 230px;
      }
    }
  }
}

@media (min-width: 1800px) {
  .products {
    margin-right: 15px;
  }
}
