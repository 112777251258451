.alert{
  position: fixed;
  z-index: 10004;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-animation: alert-anim 1s cubic-bezier(1, -0.01, 0, 0.99);
  -moz-animation: alert-anim 1s cubic-bezier(1, -0.01, 0, 0.99);
  -ms-animation: alert-anim 1s cubic-bezier(1, -0.01, 0, 0.99);
  -o-animation: alert-anim 1s cubic-bezier(1, -0.01, 0, 0.99);
  animation: alert-anim 1s cubic-bezier(1, -0.01, 0, 0.99);
  font-family: Century Gothic, sans-serif;
  padding: 18px 40px 18px 75px;
  color: #FFF;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 700;
  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  -moz-transition: 1s all cubic-bezier(1, -0.01, 0, 0.99);
  -o-transition: 1s all cubic-bezier(1, -0.01, 0, 0.99);
  -webkit-transition: 1s all cubic-bezier(1, -0.01, 0, 0.99);
  transition: 1s all cubic-bezier(1, -0.01, 0, 0.99);

  & a {
    color: #FFF;
    font-weight: 700;
    text-decoration: underline;
  }

  & i {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    width: 60px;
    font-size: 40px;
    -webkit-animation: alert-icon-anim 1s;
    -moz-animation: alert-icon-anim 1s;
    -ms-animation: alert-icon-anim 1s;
    -o-animation: alert-icon-anim 1s;
    animation: alert-icon-anim 1s;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      line-height: 0;
    }
  }

  &-success {
    border-bottom: 1px #2aff00 solid;
  }

  &__success::before {
    content: "✓";
    color: #2aff00;
    top: calc(50% - 1px) !important;
    font-size: 50px !important;
  }

  &-danger {
    border-color: #8a0000 !important;
    color: #fff !important;
    background-color: #e60606 !important;
  }

  &-close{
    position: absolute;
    top: 50%;
    line-height: 1;
    right: 15px;
    color: #FFF;
    opacity: 1;
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 25px;
    height: 27px;
    padding: 13px 0 13px 0;
    background-color: transparent;
    z-index: 30;
    cursor: pointer;
    border: none;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;

    span {
      display: block;
      width: 100%;
      height: 1px;
      background-color: transparent;

      &:before,
      &:after{
        position: absolute;
        left: 0;
        right: 0;
        height: inherit;
        background-color: inherit;
        content: '';
      }

      &:before {
        top: 13px;
        background-color: #fff;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &:after {
        bottom: 13px;
        background-color: #fff;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
}

.fa-exclamation-circle::before {
  content: "\f06a";
  color: #fff;
}

@-ms-keyframes alert-anim {
  0% {
    top: -100%; }
  60% {
    top: 0; }
  100% {
    top: 0; } }
@-o-keyframes alert-anim {
  0% {
    top: -100%; }
  60% {
    top: 0; }
  100% {
    top: 0; } }
@-webkit-keyframes alert-anim {
  0% {
    top: -100%; }
  60% {
    top: 0; }
  100% {
    top: 0; } }
@-moz-keyframes alert-anim {
  0% {
    top: -100%; }
  60% {
    top: 0; }
  100% {
    top: 0; } }
@keyframes alert-anim {
  0% {
    top: -100%; }
  60% {
    top: 0; }
  100% {
    top: 0; } }
@-ms-keyframes alert-anim2 {
  0% {
    top: -100%; }
  60% {
    top: 100%; }
  100% {
    top: 100%; } }
@-o-keyframes alert-anim2 {
  0% {
    top: -100%; }
  60% {
    top: 100%; }
  100% {
    top: 100%; } }
@-webkit-keyframes alert-anim2 {
  0% {
    top: -100%; }
  60% {
    top: 100%; }
  100% {
    top: 100%; } }
@-moz-keyframes alert-anim2 {
  0% {
    top: -100%; }
  60% {
    top: 100%; }
  100% {
    top: 100%; } }
@keyframes alert-anim2 {
  0% {
    top: -100%; }
  60% {
    top: 100%; }
  100% {
    top: 100%; } }
@-ms-keyframes alert-icon-anim {
  0% {
    font-size: 0; }
  60% {
    font-size: 0; } }
@-o-keyframes alert-icon-anim {
  0% {
    font-size: 0; }
  60% {
    font-size: 0; } }
@-webkit-keyframes alert-icon-anim {
  0% {
    font-size: 0; }
  60% {
    font-size: 0; } }
@-moz-keyframes alert-icon-anim {
  0% {
    font-size: 0; }
  60% {
    font-size: 0; } }
@keyframes alert-icon-anim {
  0% {
    font-size: 0; }
  60% {
    font-size: 0; } }

@media (min-width: $tablet-landscape) {
.alert{
  & a:hover{
    opacity: 0.7;
  }
  &-close:hover{
    opacity: 0.8;
  }
}
}
@media (min-width: $desktop) {

}