.search{
    width: 100%;
    position: relative;
    text-align: right;
    max-height: 40px;

.dropdown-menu {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    position: absolute;
    top: 30px !important;
    padding: 10px !important;
    display:none;
    box-shadow: 0 5px 6px rgba(0,0,0,0.15),0 3px 16px rgba(0,0,0,0.07),0 9px 12px rgba(0,0,0,0.09);
  z-index: 3;
  }
  .search-suggestion.product .name{
    text-decoration: none;
    transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;
    margin-bottom: 5px;
    text-align: left;

    &:hover{
      color: #ff0000;
    }
  }

  .search-suggestion.product .price{
    text-align: left;
  }

  #search .dropdown-menu,
  #search .dropdown-menu div,
  #search .dropdown-menu span {
    padding: 0;
    text-align:left;
  }
.dropdown-menu  li a,
.dropdown-menu  li.disabled {
    padding: 5px 10px;
    white-space: normal;
    color: #333;
  }


  .dropdown-menu li {
    list-style-image:  none !important;
    clear: both;
    border-bottom: 1px #e4e4e4 solid;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;

    &:hover{
      border-bottom: 1px #ff0000 solid;
    }
  }

  .search-suggestion {
    width: 100%;
  }
  .search-suggestion .more{
    margin-right: 10px;
    transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;

    &:hover{
      color: #ff0000;
    }
  }

  .search-suggestion span {
    margin-right: 5px;
  }
  .search-suggestion .label {
    color: black;
  }


  .search-suggestion .image{
    float: left;
    margin: 0 10px 0;
  }


  .search-suggestion  .image img {

  }
  .search-suggestion  .price-old {
    margin-right: 5px;
    color: #F00;
    text-decoration: line-through;
  }
  .search-suggestion  .price-new {
    font-weight: bold;
  }


    &__field{
      border-width: 1px;
      border-color: rgba(255,255,255,0.2);
      border-style: solid;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      background-color: rgba(255,255,255,1);
      width: 100%;
      height: 40px;
      font-size: 15px;
      padding: 0px 38px 0 8px;
      color: #333;
      position: relative;
      z-index: 4;
    }

    &__icon{
      position: absolute;
      top: 9px;
      right: 9px;
      display: inline-block;
      width: 22px;
      height: 22px;
      background: url(../images/icons/functional/icons-search.svg);
      background-color: transparent;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
      z-index: 4;
    }
}

.search:focus + .category__items-overlay,
.search:active + .category__items-overlay{
  height: 100%;
  overflow: visible;
  opacity: 1;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.searchpage{

  &-header{
    width: 100%;
    margin-top: 20px;

    &-wrapper{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 10px;
    }
  }

  & label{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin: 3px 0;
  }

  &-check{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #cfcfcf;
    margin: 0 auto;

    & label{
      font-size: 12px;
      padding-bottom: 10px;

      & input{
        position: relative;
        top: 2px;
      }
    }
  }

  &-form{
    width: 100%;
    max-width: 350px;
    margin: 0 auto;

    & input {
      background-color: #cfcfcf;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.428571429;
      background-image: none;
      border: 1px solid #ccc;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      margin: 0 0 10px;
    }
  }

  &-list{
    width: 100%;
    max-width: 350px;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    background-color: #0e0e0e;
    border: 1px #838383 solid;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    color: #f6f6f6;
    /*for WebKit*/
    -webkit-appearance: none;
    /* for FF */
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    /* for IE */
    -ms-appearance: none;
    appearance: none!important;
    background-image: url('../images/select-arrow.png');
    background-size: 11px;
    background-position: 330px center;
    background-repeat: no-repeat;
    margin: 0 auto 10px;
  }
}

@media (min-width: 540px) {
.search {
      position: relative;
      text-align: right;
  }
}

@media (min-width: $tablet-landscape) {
  .search {
    position: relative;
    text-align: right;
    margin: 0;
  }
    .searchpage {
    &-form input{
      margin: 0 20px 10px 0;
    }

    &-check {
      & label {
        padding-bottom: 0;
      }
    }
    &-list {
      margin: 0 20px 10px 0;
    }
  }
}

@media (min-width: 1400px) {
  .search {
    max-width: 883px;
  }
}

