.scrollhorizontal{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10px;
  width: 100%;
  margin: 10px 0;



  &__body{
    height: 3px;
    width: 100%;
    background-color: #e4e4e4;
    display: block;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-raduis: 10px;
    margin: 0 5px;
  }

  &__arrow {
    line-height: 0;
    color: #e4e4e4;
    background: none;
    border: none;
    margin: 0;
    padding: 0;

    & span:hover{
      color: #333;
    }
  }
}

@media (min-width: $phone-landscape) {

}
@media (min-width: $tablet-portrait) {

}
@media (min-width: $tablet-landscape) {

}
@media (min-width: 900px) {
  .scrollhorizontal{
    &__arrow {
      & span:hover{
        color: #333;
      }
    }
  }
}