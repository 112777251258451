.filter{
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  position: relative;

  &__item{
    width: 240px;
    font-size: 14px;
    color: $color-page-side-text;
    line-height: 1.2;
    text-align: left;
    margin: 0 auto;

    & label{
      display: block;
      padding: 10px;
      border-bottom: 1px #e4e4e4 dashed;

      &:last-child{
        border: none;
      }

      & input{
        margin-right: 5px;
      }

      & span{
        float: right;
      }
    }
  }

  &__title{
    font-size: 16px;
    color: $color-page-side-filter-title;
    line-height: 1.2;
    text-align: left;
    width: 240px;
    margin: 10px 0 0;
    padding: 10px;
    border-bottom: 1px #e4e4e4 solid;
  }

  &__modal,
  &__ocfilter{
    height: 45px;
    width: 90%;
    background-color: #fff;
    border: 1px #e4e4e4 solid;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: relative;
    margin: 30px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    font-family: Verdana, sans-serif;
    color: #333;
    text-align: center;
    padding: 12px 20px;
    line-height: 2.7;

    &-btn{
      height: 20px;
      width: 20px;
      background-image: url('../images/select-arrow.png');
      background-size: 20px;
      background-repeat: no-repeat;
    }

    &__wrapper{
      width: 90%;
      margin: 0 auto;
    }
  }

  &-mobile{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__btn{
    display: block;
    font-family: Verdana, sans-serif;
    font-size: 14px;
    width: 100%;
    max-width: 180px;
    background: none;
    border: 1px #e4e4e4 solid;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #333;
    padding: 8px 5px;
    font-weight: normal;
    text-align: center;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    line-height: 1;
    margin: 0 auto;
  }

  .selected-options{
    width: 100%;
    margin-top: 10px;
  }

  .ocfilter-option{
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .btn{
      width: 100%;
      background: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px #e4e4e4 dashed;
      color: #333;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:before {
        content: '-';
        display: block;
        margin-right: 5px;
        line-height: 2.5;
      }

      & span {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        transition: color 0.3s ease;
        padding: 7px 0;

        &::after {
          content: "\f335" !important;
          font-family: dashicons;
          color: #333;
          font-size: 22px;
          line-height: 0.9;
          width: 13px;
          height: 11px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .disabled{
    color: #575751;
    & input{
      opacity: 0.2;
    }
  }
  .popover-content {
    background: none;
    color: #333;
    border: 1px #cfcfcf solid;
    padding: 7px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    position: relative;
    top: 14px;
    background: #fff;
    z-index: 40;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;

    & .btn{
      background: none;
      border: none;
      line-height: 1.5;
      color: #333;
      font-family: "Open Sans";
      font-size: 13px;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
    }

    &:hover{
      border-color: #ff0000;
      background-color: #ff0000;

      & .btn{
        color: #fff;
      }
    }
  }

  .popover>.arrow,
  .popover>.arrow:after {
    position:absolute;
    display:block;
    width:0;
    height:0;
    border-color:transparent;
    border-style:solid
  }
  .popover>.arrow {
    border-width:11px
  }
  .popover>.arrow:after {
    content:"";
    border-width:10px
  }
  .popover.top>.arrow {
    bottom:-11px;
    left:50%;
    margin-left:-11px;
    border-top-color:#999;
    border-top-color:rgba(0,0,0,.25);
    border-bottom-width:0
  }
  .popover.top>.arrow:after {
    bottom:1px;
    margin-left:-10px;
    content:" ";
    border-top-color:#000;
    border-bottom-width:0
  }
  .popover.right>.arrow {
    top:50%;
    left:-11px;
    margin-top:-11px;
    border-right-color:#999;
    border-right-color:rgba(0,0,0,.25);
    border-left-width:0
  }
  .popover.right>.arrow:after {
    bottom:-10px;
    left:10px;
    content:" ";
    border-right-color:#fff;
    border-left-width:0
  }
  .popover.bottom>.arrow {
    top:-11px;
    left:50%;
    margin-left:-11px;
    border-top-width:0;
    border-bottom-color:#999;
    border-bottom-color:rgba(0,0,0,.25)
  }
  .popover.bottom>.arrow:after {
    top:16px !important;
    left: 0;
    content:" ";
    border-top-width:0;
    border-bottom-color:#fff
  }
  .popover.left>.arrow {
    top:50%;
    right:-11px;
    margin-top:-11px;
    border-right-width:0;
    border-left-color:#999;
    border-left-color:rgba(0,0,0,.25)
  }
  .popover.left>.arrow:after {
    right:1px;
    bottom:-10px;
    content:" ";
    border-right-width:0;
    border-left-color:#fff
  }

}

.ocfilter-mobile{
  background-color: #000;


  .ocfilter-option-popover {
    left: 50px !important;
  }
}

/*=Mobile */
.ocf-offcanvas{
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0,0.5);;
  bottom: 0;
  z-index: 20000;
  height: 100%;
  width: 100%;
  padding: 0;
  left:-100%;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.ocf-offcanvas.active{
  left:0;

  .ocf-offcanvas-body{
    right:0 !important;
  }
}
.ocf-offcanvas .ocfilter-mobile-handle{
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 256px;
  border-top: #e4e4e4 1px solid;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ocf-offcanvas .ocfilter-mobile-handle .btn{border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-close{
  width: 200px;
  background: none;
  border: 1px #e4e4e4 solid;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #000;
  padding: 8px 5px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &::after {
    content: "\f335" !important;
    font-family: dashicons;
    color: #ff0000;
    font-size: 22px;
    line-height: 0.9;
    width: 13px;
    height: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0 0 7px;
  }

}

.ocf-offcanvas .ocf-offcanvas-body{
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -200%;
  width: 256px;
  background-color: #fff;

  -webkit-transition: right 0.3s ease;
  -moz-transition: right 0.3s ease;
  -ms-transition: right 0.3s ease;
  -o-transition: right 0.3s ease;
  transition: right 0.3s ease;
}

@media (min-width: $phone-landscape) {
  .filter {
    &__modal,
    &__ocfilter{
      width: 95%;
    }
  }
}

@media (min-width: $tablet-landscape) {
.filter{
  &__btn{
    &:hover {
      color: #ff0000;
      border: 1px #ff0000 solid;
    }
  }
}
}
@media (min-width: $desktop) {
.filter{
  &__modal,
  &__ocfilter,
  .btn-close{
    display: none;
  }

  .ocfilter-option{
    & .btn span:hover:after {
      color: #ff0000;
    }
  }

  .popover-content {
    top: 0px;
    left: 9px;
  }

  .popover.bottom>.arrow:after {
    top: 1px;
    margin-left: -10px;
  }
}
  .ocf-offcanvas .ocfilter-mobile-handle{
    display: none;
  }

}