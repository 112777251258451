.history{
  width: 100% !important;

  &__order{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px #e4e4e4 solid;
    /*-webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;*/
    padding: 10px;
    margin-bottom: 20px;

    &-block{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px #d9d9d9 solid;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }

    &-title{
      font-size: 14px;
      text-align: center;
    }

    &-value{
      font-size: 12px;
      text-align: center;
    }
  }

  &__btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: $desktop) {
.history{
  &__order {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    &-block{
      width: 14%;
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-client{
      width: 25% !important;
    }

    &-title{
      width: 90%;
      border-bottom: 1px #d9d9d9 solid;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
  }
}
}

@media (min-width: 1240px){
  .history {
    &__order {
      align-items: center;
    }
  }
}