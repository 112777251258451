/* Подложка */
#modal__overlay {
  z-index: 20;
  position: fixed;
  background: rgba(0,0,0,0.95);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: none;
}

/* Основной блок */
.modal{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 45%;
  left: 50%;
  display: none;
  opacity: 0;
  z-index: 21;
  transform: translate(-50%, -50%);
  overflow-y: auto;

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 34px;
    height: 27px;
    padding: 13px 0 13px 0;
    background-color: transparent;
    z-index: 30;
    cursor: pointer;
    border: none;

    span {
      display: block;
      width: 100%;
      height: 1px;
      background-color: transparent;

      &:before,
      &:after{
        position: absolute;
        left: 0;
        right: 0;
        height: inherit;
        background-color: inherit;
        content: '';
      }

      &:before {
        top: 13px;
        background-color: #fff;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &:after {
        bottom: 13px;
        background-color: #fff;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }

  &__filter{
    .filter{
      margin: 0 auto 20px;
    }
  }
}

@media (min-width: $desktop) {
  .modal__filter{
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    right:0;
    display: block;
    opacity: 1;
    z-index: 0;
    transform: none;
    overflow: visible;

    &__close {
      display: none;
    }

    &-logo{
      display: none;
    }
    .filter{
      margin: 0 0 20px 0;
    }
  }
}
