
@media (min-width: $phone-landscape) {
  .phone-landscape-none{
    display: none;
  }
  .phone-landscape-block{
    display: block;
  }

  .phone-landscape-hidden{
    overflow: hidden;
  }

  .phone-landscape-visible{
    overflow: visible;
  }

}
@media (min-width: $tablet-portrait) {
  .tablet-portrait-none{
    display: none;
  }
  .tablet-portrait-block{
    display: block;
  }

  .tablet-portrait-hidden{
    overflow: hidden;
  }

  .tablet-portrait-visible{
    overflow: visible;
  }
}
@media (min-width: $tablet-landscape) {
  .tablet-landscape-none{
    display: none;
  }
  .tablet-landscape-block{
    display: block;
  }

  .tablet-landscape-hidden{
    overflow: hidden;
  }

  .tablet-landscape-visible{
    overflow: visible;
  }
}
@media (min-width: $desktop) {
  .desktop-none{
    display: none;
  }
  .desktop-block{
    display: block;
  }

  .desktop-hidden{
    overflow: hidden;
  }

  .desktop-visible{
    overflow: visible;
  }
}