.viewed{

  &__title{
    font-size: 18px;
    color: #333;
    font-weight: normal;
    text-align: left;
    margin: 35px 15px 15px;
    position: relative;
  }

  &__wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: hidden;
    max-height: 518px;
    padding: 0 20px;
  }

  &__item{
    margin-right: 10px;

    &:last-child{
      margin-right: 0;
    }
  }

}

@media (min-width: 495px) {
  .viewed {

    &::after {
     display: none;
    }

  }
}

@media (min-width: 535px) {
  .viewed {

    &::after {
      display: block;
    }

  }
}

@media (min-width: 900px) {
  .viewed {

    &__item {
      margin-right: 38px;
    }
  }
}

@media (min-width: 1024px) {
  .viewed {
    &::after {
      display: none;
    }
    &__item{
      margin-right: 10px;
    }

  }
}

@media (min-width: 1064px) {
  .viewed {
    &::after {
      display: block;
    }

  }
}
@media (min-width: 1214px) {
  .viewed {

    &__item{
      margin-right: 8px;
    }
  }
}
@media (min-width: 1366px) {
  .viewed {
    &__item{
      margin-right: 60px;
    }
    &::after {
      display: none;
    }
  }
}

@media (min-width: $bigdesktop) {
  .viewed {

    &__item{
      margin-right: 10px;
    }
  }
}
