.footer{
  background-color: #000;;
  padding: 45px 0;

  &__wrapper{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }


  &__copyright{
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    line-height: 1.5;

    & span{
      display: block;
    }
  }

  &__callcenter{
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    margin: 24px 0 15px;
    line-height: 1.5;

    &-title{
      font-size: 16px;
    }

    &-time{
      font-size: 14px;
      color: #858282;
    }
  }

  &__block{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 15px;

    &-title{
      font-size: 16px;
      color: #fff;
      margin-bottom: 5px;
    }

    &-items{
      font-size: 14px;

      & a{
        color: #a1a1a1;
        display:block;
        line-height: 1.5;

        &:hover{
          color: #d2d2d2;
        }
      }
    }
  }

}

@media (min-width: $desktop) {

  .footer{

    &__wrapper{
      justify-content: space-between;
      width: 100%;
      max-width: 1360px;
    }

    &__callcenter{
      margin-top: 25px;
    }

    &__block{
      margin: 0;

      &-title{
        font-size: 18px;
      }

      &-items{
        & a{
          &:hover{
            color: #ff0000;
          }
        }
      }
    }
  }

}

