.logo{
  display: inline-block;
  width: 146px;
  height: auto;
  margin-bottom: 5px;
  order: 0;
}


@media (min-width: $desktop) {
  .logo{
    width: 170px;
    margin-top: 0;
    margin-right: 35px;
  }
}
@media (min-width: 1400px) {
  .logo{
    width: 250px;
  }
}