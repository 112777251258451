.mmenu{
  z-index: 10501;
  position: fixed;
  background-color: #fff;
  height: calc(100% - 123.467px);
  top: 124px;
  left: -500%;
  bottom: 0;
  width: 0;
  max-width: 400px;
  transition: all 0.3s ease;
  font-size: 14px;

  &__wrapper{
    height: 100%;
    overflow-y: auto;
  }
}

.mmenu__overlay{
  position: fixed;
  width: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index:4;
  overflow: 0;
  height: 0;
  opacity: 0;
  transition: opacity 0.3s ease;

  &-open{
    height: 100%;
    width: 100%;
    overflow: visible;
    opacity: 1;
  }
}

.nmenu__header{
  z-index: 40;

  .menu__btn {

    span {
      background-color: transparent;


      &:before {
        top: 13px;
        background-color: #fff;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &:after {
        bottom: 13px;
        background-color: #fff;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

  }
}

.nmenu__open{
  left: 0;
  right: 0;
  width: 100%;

  .menu__btn {
    position: fixed;

    span {
      background-color: transparent;


      &:before {
        top: 13px;
        background-color: #fff;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &:after {
        bottom: 13px;
        background-color: #fff;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

  }
}

.slinky-menu {
  overflow: hidden;
  transform: translateZ(0); }
.slinky-menu > ul {
  left: 0;
  position: relative;
  transform: translateZ(0); }
.slinky-menu ul,
.slinky-menu li {
  list-style: none;
  margin: 0; }
.slinky-menu ul {
  width: 100%; }
.slinky-menu a,
.slinky-menu button {
  align-items: center;
  display: flex;
  background: none;
  border: none;
}
.slinky-menu a span,
.slinky-menu button span{
  line-height: 1.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  position: relative;

  & i{
    line-height: 1;
    margin-right: 10px;
  }

  & b,
  & span span{
    font-weight: normal;
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    background-color: #cfcfcf;
    border: 1px #cfcfcf solid;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
    color: #333;
    line-height: 0.5;;
    top: -4px;
  }
}
.slinky-menu li ul {
  display: none;
  left: 100%;
  position: absolute;
  top: 0; }
.slinky-menu .header-menu {
  display: flex;
  margin: 0;
  border-bottom: 2px #e4e4e4 solid;
}
.slinky-menu .header-menu .title {
  flex: 1;
  line-height: 1.4;
  margin: 0;
  order: 1;
  font-size: 16px;
  padding-left: 0;
}

.slinky-theme-default {}
.slinky-theme-default .title {
  color: #333;
  padding: 1em; }
.slinky-theme-default li {
  line-height: 1;
  margin: 0 0.5em;
}
.slinky-theme-default a:not(.back),
.slinky-theme-default button:not(.back) {
  color: #333;
  padding: 1em 0.5em;
  font-size: 14px;
}

.slinky-theme-default a:not(.back):hover,
.slinky-theme-default a:not(.back):active,
.slinky-theme-default button:not(.back):hover,
.slinky-theme-default button:not(.back):active {
  color: #ff0000;
}

.slinky-theme-default .next::after,
.slinky-theme-default .back::before {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+ICAgIDxwYXRoIGQ9Ik0xMi4yMTkgMi4yODFMMTAuNzggMy43MiAxOC4wNjIgMTFIMnYyaDE2LjA2M2wtNy4yODIgNy4yODEgMS40MzggMS40MzggOS05IC42ODctLjcxOS0uNjg3LS43MTl6IiAvPjwvc3ZnPg==) center no-repeat;
  background-size: 1em;
  content: '';
  height: 1em;
  opacity: 0.25;
  transition: 200ms;
  width: 1em; }
.slinky-theme-default .next::after {
  margin-left: 1em; }
.slinky-theme-default .back::before {
  padding: 1em;
  transform: scaleX(-1); }
.slinky-theme-default .next:hover::after,
.slinky-theme-default .back:hover::before {
  opacity: 0.75; }
.slinky-theme-default .next:active::after,
.slinky-theme-default .back:active::before {
  opacity: 1; }