.fxicons{
  background: none;
  border: none;

  &:before {
    content: '';
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.fxicons-mobile-menu:before{
  width: 20px;
  height:20px;
  background-size: 20px 20px;
}

.fxicons-header:before{
  width: 50px;
  height:35px;
  background-size: 50px 35px;
}

.fxicons-wishlist:before{
  width: 27px;
  height:27px;
  background-size: 27px 27px;
}

.fxicons-products{
  display: block;
  width: 26px;
  height:20px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  transition: all 0.3s ease;
  -webkit-transition: all .3s ease;

  &:before {
    content: '';
    display: block;
    width: 26px;
    height:20px;
    background-size: 34px 20px;
    background-position: center;
  }
}

.fxicons-product-deliveryandpay{
  display: block;
  width: 26px;
  height:20px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  transition: all 0.3s ease;
  -webkit-transition: all .3s ease;

  &:before {
    content: '';
    display: block;
    width: 26px;
    height:20px;
    background-size: 34px 20px;
    background-position: center;
  }
}

.fxicons-header-mobile-basket{
  position: absolute;
  top: 8px;
  right: 0px;
  -webkit-transition:opacity 0.3s ease;
  transition:opacity 0.3s ease;

  &:before {
    content: '';
    display: block;
    height: 30px;
    width: 35px;
    background-size: 35px 30px;
    background-position: center;
  }

  & span{
    position: absolute;
    right: -4px;
    bottom: -3px;
    width: 20px;
    height: 20px;
    background-color: #cfcfcf;
    border: 1px #cfcfcf solid;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #333;
    line-height: 0.5;
    font-size: 11px;
  }
}
.fxicons-cashback-black{
  cursor: auto;
  margin: 7px 0;

  &-text{
    font-size: 14px;

    & b{
      font-weight: bold;
      border-bottom: 1px #ff0000 dashed;
      color: #ff0000;
      padding: 2px 0;
    }
  }
}
.fxicons-home-black:before{background-image: url("../images/icons/functional/icons-home-black.svg");}
.fxicons-home-white:before{background-image: url("../images/icons/functional/icons-home-white.svg");}
.fxicons-catalog-black:before{background-image: url("../images/icons/functional/icons-catalog-black.svg");}
.fxicons-basket-black:before{background-image: url("../images/icons/functional/icons-basket-black.svg");}
.fxicons-basket-white:before{background-image: url("../images/icons/functional/icons-basket-white.svg");}
.fxicons-comparison-black:before{background-image: url("../images/icons/functional/icons-comparison-black.svg");}
.fxicons-comparison-white:before{background-image: url("../images/icons/functional/icons-comparison-white.svg");}
.fxicons-desires-black:before{background-image: url("../images/icons/functional/icons-desires-black.svg");}
.fxicons-desires-white:before{background-image: url("../images/icons/functional/icons-desires-white.svg");}
.fxicons-connectivity-black:before{background-image: url("../images/icons/functional/icons-connectivity-black.svg");}
.fxicons-connectivity-white:before{background-image: url("../images/icons/functional/icons-connectivity-white.svg");}
.fxicons-profile-black:before{background-image: url("../images/icons/functional/icons-profile-balck.svg");}
.fxicons-profile-white:before{background-image: url("../images/icons/functional/icons-profile-white.svg");}
.fxicons-cooperation-black:before{background-image: url("../images/icons/functional/icons-cooperation-black.svg");}
.fxicons-cooperation-white:before{background-image: url("../images/icons/functional/icons-cooperation-white.svg");}
.fxicons-shipping-black:before{background-image: url("../images/icons/functional/icons-shipping-black.svg");}
.fxicons-shipping-white:before{background-image: url("../images/icons/functional/icons-shipping-white.svg");}
.fxicons-company-black:before{background-image: url("../images/icons/functional/icons-company-black.svg");}
.fxicons-callback-black:before{background-image: url("../images/icons/functional/icons-callback-black.svg");}
.fxicons-call-black:before{background-image: url("../images/icons/functional/icons-call-black.svg");}
.fxicons-language-black:before{background-image: url("../images/icons/functional/icons-language-black.svg");}
.fxicons-cashback-black:before{background-image: url("../images/icons/functional/icons-rewards-cashback-black.svg");width: 34px;}

.fxicons-tourism-black:before{background-image: url("../images/icons/catalog/icons-tourism-black.svg");}
.fxicons-yoga-black:before{background-image: url("../images/icons/catalog/icons-yoga-black.svg");}
.fxicons-fitness-black:before{background-image: url("../images/icons/catalog/icons-fitness-black.svg");}
.fxicons-sport-black:before{background-image: url("../images/icons/catalog/icons-sport-black.svg");}
.fxicons-cycling-black:before{background-image: url("../images/icons/catalog/icons-cycling-black.svg");}
.fxicons-sportswear-black:before{background-image: url("../images/icons/catalog/icons-sportswear-black.svg");}
.fxicons-sports-nutrition-black:before{background-image: url("../images/icons/catalog/icons-sports-nutrition-black.svg");}
.fxicons-accessories-black:before{background-image: url("../images/icons/catalog/icons-accessories-black.svg");}

.fxicons-delivery-novaposhta:before{background-image: url("../images/icons/methods/icons-method-product-shipping-novaposhta.svg");}
.fxicons-delivery-ukrposhta:before{background-image: url("../images/icons/methods/icons-method-product-shipping-ukrposhta.svg");}
.fxicons-delivery-free:before{background-image: url("../images/icons/methods/icons-method-product-shipping-free-black.svg");}

.fxicons-pay-card:before{background-image: url("../images/icons/methods/icons-method-pay-card-black.svg");background-size: 26px 20px;}
.fxicons-pay-cash:before{background-image: url("../images/icons/methods/icons-method-pay-cash-black.svg");}
.fxicons-pay-cod:before{background-image: url("../images/icons/methods/icons-method-pay-cod-black.svg");background-size: 26px 20px;}



@media (min-width: $desktop) {
  .fxicons-header-mobile-basket{
    display: none !important;
  }
}