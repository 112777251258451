.postpay{

  &__title{
    color: #333333;
    font-size: 18px;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  &__description{
    color: #333;
    display: block;
    font-size: 14px;
    width: 80%;
    line-height: 1.5;
    margin: 0 auto 20px;
  }
}

.pay{
  display: block;

  &__wrapper{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }

  &__method{
    display: block;
    width: 100%;
    margin-bottom: 20px;

    & img{
      display: block;
      width: 200px;
      height: 200px;
      margin: 0 auto;
    }

    &-title{
      color: #333;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
    }

    &-description{
      color: #333;
      font-size: 14px;
      line-height: 1.5;
      display: block;
    }
  }
}

.delivery{

  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
  }
  &__post{
    display: block;
    width: 100%;
    margin-bottom: 20px;

    & img{
      display: block;
      width: 200px;
      height: 200px;
      margin: 0 auto;
    }

    &-description{
      color: #333;
      font-size: 14px;
      line-height: 1.5;
      margin-top: 5px;
      display: block;

      & a{
        color: #333;
        display: block;

        &:before{
          content: '-';
          margin-right: 5px;
        }
      }
    }
  }
}

@media (min-width: $phone-landscape){

  .delivery{
    &__post{

      &-description{
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}

@media (min-width: $tablet-portrait) {
  .postpay{

    &__title{
      font-size: 22px;
      color: #333333;
    }

    &__description{
      width: 90%;
    }
  }

  .pay{
    margin-bottom: 40px;

    &__wrapper{
      flex-direction: row;
      justify-content: space-around;
      width: 90%;
    }

    &__method{
      width: 48%;

      &-title{
        font-size: 18px;
      }

      &-description{
        width: 100%;
      }
    }
  }

  .delivery{

    &__wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
    }

    &__post{
      width: 300px;
      margin:0 10px 0 0;

      &:last-child{
        margin:0;
      }

      &-description{
        width: 100%;
      }
    }
  }
}

@media (min-width: $tablet-landscape) {

  .pay{

    &__method{
      width: 400px;

    }
  }

  .delivery{
    &__post{
      &-description{
        & a:hover,
        & a.visited:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
