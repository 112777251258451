.ex-sort {
  width:185px;
  margin-bottom: 20px;

  select {
    border-bottom: 1px #e4e4e4 solid;
    background: none;
    color: #333;
    border-left: none;
    border-right: none;
    border-top: none;
    width: 185px;
    appearance: none !important;
    /*for WebKit*/
    -webkit-appearance: none;
    /* for FF */
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    /* for IE */
    -ms-appearance: none;
    appearance: none !important;
    background-image: url('../images/select-arrow.png');
    background-size: 11px;
    background-position: right center;
    background-repeat: no-repeat;
    border-radius: 0 !important;

    option {
      background-color: #2b2b2b;
      color: #333;
    }
  }
}

.ex-button-right-block{
  max-height: 32px;
  max-width: 135.9px;
}

.sort__title{
  color: #333 !important;
  font-size: 14px;
  margin-bottom: 10px;
}

.review {
  width: 100%;
  height: auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px $color-border-product-review solid;
  margin-bottom: 20px;
  color: $color-product-review-text;
  font-size: 14px;

  &__allmedia{
    &-wrapper{

    }
  }

  &__top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
  }
  &_upbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  &__wrapper{
    width: 100%;
  }

  &__content {
    margin: 0 20px;
    overflow: hidden;

    &-text {
      display: inline-block;
      border-bottom: 1px rgba(207, 207, 207, 0.08) solid;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    &-dignity,
    &-limitations {
      &-text {
        margin: 0 10px;
      }
    }

    &-dignity {
      margin-bottom: 10px;

      &-title {
        color: #00ad00;
      }
    }

    &-limitations {
      &-title {
        color: #bb0303;
      }
    }


    &-media {
      padding: 20px 14px 0;

      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
      }

      &-photo {
        display: block;
        height: 100px;
        width: auto;
        margin: 0 7px 15px;

        & img {
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border-radius: 8px;
        }
      }

      &-video {
        display: block;
        height: 100px;
        width: auto;
        margin: 0 7px 15px;

        & a{
          height: 100px;
          width: 170px;
          border-radius: 2px;
          overflow: hidden;
          position: relative;
          display: block;
          margin: 3px 2px 15px;

          & img{
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
          }
        }

        #icon-youtube{
          position: absolute;
          top: 0;
          left: calc(50% - 15px);
        }
      }

    }
  }

  &__footer{
    padding: 10px 20px;
  }

  &__panel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-btn{
      background: none;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      color: $color-buttons-product-review;
      font-size: 13px;
      transition: color .3s ease;

      &:hover{
        color: #ff0000;
      }
    }

    &-like{
      margin-right: 10px;
      cursor: pointer;

      &-btn{
        color: #00ad00;
        font-size: 20px !important;
      }
    }
    &-dislike{
      cursor: pointer;

      &-btn{
        color: #bb0303;
        font-size: 20px !important;
      }
    }
  }

  &__answers{
    margin: 0 20px 10px 15%;
    border-top: 1px rgba(207, 207, 207, 0.08) solid;

    &-item{
      margin-top: 20px;

      &-top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &-name{
        font-size: 15px;
      }

      &-text{
        margin: 5px 0;
      }

    }


  }

  &__answer{
    &-btns{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__name{
    //max-width: 50%;
    overflow: hidden;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__evaluation{
    padding: 10px 20px 0;
    text-align: center;
    font-size: 12px;
    color: #7b7a7a;
    border-top: 1px dashed #7b7a7a;
    margin-top: 10px;
  }

  &__write{
    font-size: 22px;
    margin: 20px 10px 0;
    text-align: center;
  }
  &__form{
    padding: 20px 0;
    font-size: 13px;

    &-title{
      display: block;
      font-size: 13px;
      margin-bottom: 5px;
    }

    &-name{
      & input{
        width: 100%;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background: none;
      }
    }

    &-content{
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 5px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      border: 1px $color-border-product-review-input solid;
      background: none;
    }

    &_media{
      &-block{
        float: left;
        margin-right: 18px;
      }

      &-text{
        position: relative;
        font-size: 14px;
        line-height: 18px;
        color: #888;
        max-width: 370px;
        padding: 0 0 0 53px;
        -ms-flex-order: 1;
        order: 1;
        overflow: hidden;
        margin: 0 0 15px 0;
      }

      &-arrow{
        display: block;
        position: absolute;
        top: 13px;
        left: 0;
        width: 47px;
        height: 22px;
      }
    }

    &-footer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    &-button{
      background: none;
      border: 1px $color-border-product-review-buttons solid;
      font-family: Verdana, sans-serif;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      color: $color-buttons-product-review;
      transition: all .3s ease;
      line-height: 2.5;
      font-weight: normal;

      &:hover{
        border-color: $color-border-product-review-buttons-hover;
        color: #fff;
        background-color: #ff0000;
      }
    }
  }

}

@media (min-width: $phone-landscape) {
  .ex-sort{
    margin: 0;
  }
.review{

  &_upbar{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__date{
    text-align: right;
  }
  &__form{
    &-footer{
      flex-direction: row;
      justify-content: center;
    }
  }
}
}

@media (min-width: $tablet-portrait) {
  .ex-sort{
    margin-bottom: 20px;
  }
  .review {
    width: 330px;

    &_upbar{
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &__block{
      width: 350px;
    }
  }
  .ex-drag{
    max-width: 330px;
  }
  #ex_reviews{
    margin-left: 20px;
  }
}

@media (min-width: $tablet-landscape) {
  .ex-sort{
    margin: 0;
  }

  .review {
    width:430px;

    &__block{
      width: 450px;
    }

      &_upbar{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

    &__name {
      font-size: 18px;
    }

    &__content {

      &-text {
        margin: 0 10px 10px;
      }
    }

    &__answer{
      &-btns {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .ex-drag{
    max-width: 430px;
  }
}
@media (min-width: 1366px) {
  .review {
    width:450px;
    &__block{
      width: 470px;
    }
  }
  .ex-drag{
    max-width: 450px;
  }
}
@media (min-width: 1900px) {
  .review {
    width:600px;
    &__block{
      width: 620px;
    }
  }

  .ex-drag{
    max-width: 600px;
  }
}