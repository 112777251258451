.language{
  display: none;
  margin-left: 10px;
  &__header{
    &-btn{
      display: block;
      color: #fff;
      font-size: 14px;
      border: none;
      background: none;
      padding: 1px 3px 3px 3px;
      transition: all 0.3s ease;

      &:hover{
        color: #ff0000;
      }
    }
  }

  &__mobile{
    display: flex;

    &-buttons{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & button{
        padding: 0 !important;
        height: 22px;
        margin: 0 10px;
      }
    }
  }
}
@media (min-width: $desktop) {
  .language{
    display: block;
  }
}
@media (min-width: $tablet-portrait) {

}
@media (min-width: $tablet-landscape) {

}
@media (min-width: $desktop) {

}