.breadcrumbs{

  &__product {
    position: absolute;
    top: -85px;
  }
    & ol{
      margin: 0;
      padding: 0;

      & li{
        color: #636363 !important;
        margin:0 4px 0 0!important;

        &:before{
          display: none;
        }

        & a{
          color: #636363 !important;

          &:before{
            border-color: #636363 !important;
          }
        }
      }
    }


  & ol{
    & li {
      font-family: "Open Sans", sans-serif;
      color: #757575;
      padding-right: 12px;
      margin-right: 4px;
      position: relative;
      font-size: 13px;
      display: inline-block;
      line-height: 1.2;

      &:last-child{
        display: inline;
      }

      & a {
        color: #757575;

        &:before {
          content: '';
          position: absolute;
          top: 6px;
          right: 0;
          height: 5px;
          width: 5px;
          border-top: 1px solid #757575;
          border-right: 1px solid #757575;
          border-bottom: none;
          border-left: none;
          transform: rotate(45deg);
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .breadcrumbs{
    & ol {
      & li {
        & a {

          &:hover {
            text-decoration: underline;
          }

        }
      }
    }
  }
}