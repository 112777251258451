.menu{
  width: 100%;
  max-width: 920px;
  margin-bottom: 10px;
  display: none;

  &__btn {
    position: absolute;
    top: 10px;
    left: 0;
    width: 34px;
    height: 27px;
    padding: 13px 0 13px 0;
    background-color: transparent;
    z-index: 10;
    cursor: pointer;
    border: none;

    span{
      display: block;
      width: 100%;
      height: 1px;
      background-color: #fff;
      -webkit-transition: background-color 0.2s ease;
      transition: background-color 0.2s ease;

      &:before,
      &:after{
        position: absolute;
        left: 0;
        right: 0;
        height: inherit;
        background-color: inherit;
        content: '';
        -webkit-transition: top .3s ease, -webkit-transform .3s ease, background-color .3s ease;
        transition: top .3s ease-in-out, transform .3s ease, background-color .3s ease;

      }

      &:before{
        top: 3px;
      }

      &:after{
        bottom: 3px;
      }

    }

  }

  &__item{
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
    z-index: 4;

    a {
      color: #fff;
      display: block;
      text-align: center;
      padding-top: 25px;
      font-size: 16px;
    }

    a:link,
    a:visited,
    a:active {
      display: inline-block;
      position: relative;
      color: #fff;
      margin: 0 15px 0 0;
      padding: 0;
      text-align: center;
      font-size: 13px;

      &:last-child{
        margin: 0;
      }
    }
  }

}

@media (min-width: $desktop) {
  .menu{
    display: flex;
    flex-direction: row;
    align-items: center;

    &__btn {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }
}

@media (min-width: $desktop) {
  .menu {

    &__item {

      a::before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 0;
        background-color: #ece8e8;
        -webkit-transition: width .2s ease, left .2s ease;
        transition: width .2s ease, left .2s ease;
        left: 50%;
        bottom: -3px;
      }

      a:hover{
        color: #ff0000;

        &::before {
          width: 100%;
          left: 0;
        }
      }
        a:hover,
        a.visited:hover {
          color: #fff;
        }
    }
  }
}
