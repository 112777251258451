.compare{
  color: #333;
  padding-right: 20px;

  &__buy{
    border-radius: 8px;
    -webkit-birder-radius: 8px;
    -moz-border-radius: 8px;
  }

  &__wrapper{
    margin: 0 auto;
    padding: 20px 0;
    border-top: 1px #e4e4e4 solid;
    border-bottom: 1px #e4e4e4 solid;
    overflow: hidden;
    overflow-x: scroll;
    overflow-x: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar { width: 0; }
  }

  &__row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px 20px;
    border-bottom: 1px rgba(131,131,131,0.2) dashed;

    & a{
      color: #333;
      display: block;
    }
  }

  &__fixeditem,
  &__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__fixeditem {
    width: 15%;
    min-width: 135px;
  }

  &__item{
    width: 30%;
    min-width: 230px;
    margin-left: 15px;
    align-items: center;
  }

}
@media (min-width: $phone-landscape) {
.compare{
  padding-right: 0;
  &__wrapper{
    margin: 0 30px 0;
  }
  &__fixeditem {
    min-width: 175px;
  }
}
}
@media (min-width: $tablet-portrait) {

}
@media (min-width: $tablet-landscape) {
  .compare {

    &__row:hover{
      background-color: #ececec;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
  }
}
@media (min-width: $desktop) {

}