.profile{
  width: 90%;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  margin: 0 auto;

  &__tr{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px #e4e4e4 dashed;
    font-size: 14px;

    &-title{
      border-bottom: 1px #e4e4e4 solid;
    }
  }

  &__td{
    padding: 10px;
    width: 100%;

    & a{
      color: #333;
      transition: color 0.3s ease;
      -webkit-transition: color 0.3s ease;
      -moz-transition: color 0.3s ease;

      &:hover{
        color: #ff0000;
      }
    }
  }

  &__transaction{
    &-date{
      max-width: 130px;
    }
    &-money{
      max-width: 150px;
    }
  }

  &__modify{
    padding-top: 60px;
  }

  &__rightblock{
    margin-left: 0;
    width: 100%;
  }

  &__title{
    font-size: 26px;
    font-family: "Open Sans", sans-serif;
    color: #333;
    text-align: center;
    line-height: 1.5;
    font-weight: normal;
    margin: 20px 0 20px 0;
  }

  &__block{
    width: 100%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin: 0 auto 20px;

    &-title{
      font-size: 18px;
      color: #333;
      text-align: center;
      margin-bottom: 15px;
      font-weight: normal;
    }

    & a {
      color: #333;
      font-size: 16px !important;
      border-bottom: 1px #e4e4e4 solid;
      text-align: left;
      padding: 8px 5px;
      -webkit-transition: color .3s ease;
      transition: color .3s ease;
      line-height: 1.3;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      &:last-child {
        border: none;
      }
    }
  }

  &__main {

    & a {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__registration{
    width: 400px;
    padding: 0 20px;
    margin-bottom: 20px;

    & p{
      font-size: 12px;
      line-height: 1.5;
      color: #333;
    }
  }

  &__login{
    width: 340px;
    padding: 0 15px;
    margin-bottom: 20px;
  }

  &__form{

    &-title{
      display: block;
      font-size: 12px;
      margin: 20px 0 10px;
      color: #333;
    }

    &-field{
      width: 100%;
      background: none;
      border-bottom: 1px #e4e4e4 dashed;
      border-top: none;
      border-left: none;
      border-right: none;
      color: #333;
      height: 30px;
      padding: 6px 8px !important;
    }
  }

  &__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;

    &-btn{
      font-family: Verdana, sans-serif;
      font-size: 14px;
      width: 100%;
      max-width: 180px;
      background: none;
      border: 1px #e4e4e4 solid;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      color: #333;
      margin-right: 10px;
      padding: 8px 5px;
      font-weight: normal;
      text-align: center;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      line-height: 1;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &__menu{
    width: 290px !important;
    margin: 0 auto;

  }

  &__off{
    display: none;
  }

  &__pages{
    width: 100%;
    color: #333;

    & legend{
      width: 100%;
      font-size: 18px;
      font-family: "Open Sans", sans-serif;
      border-bottom: 1px #e4e4e4 solid;
      padding: 20px 0 10px;
    }

    &-line{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 8px 0;

      &-name{
        width: 100%;
        font-size: 14px;
        padding-right: 15px;
        height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
      }

      &-control::after {
        content: '*';
        color: #FF0000;
        margin-left: 3px;
      }

      &-field{
        background-color: #fff;
        border: 1px #e4e4e4 dashed;
        border-top: none;
        border-left: none;
        border-right: none;
        color: #333;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        font-size: 14px;
        padding: 6px 8px !important;
      }

      &-items{
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & label{
          margin-bottom: 10px;
        }
      }

      &-data{
        border-bottom: none;
        font-size: 13px !important;
      }

      &-list{
        background-color: #fff;
        border: 1px #e4e4e4 dashed;
        border-top: none;
        border-left: none;
        border-right: none;
        /*-webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;*/
        color: #333;
        width: 100%;
        padding: 5px 0;
        /*for WebKit*/
        -webkit-appearance: none;
        /* for FF */
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
        /* for IE */
        -ms-appearance: none;
        appearance: none !important;
        background-image: url('../images/select-arrow.png');
        background-size: 11px;
        background-position: 98% center;
        background-repeat: no-repeat;
      }

      &-danger{
        width: 100%;
        font-size: 12px;
        text-align: center;
        padding: 5px 10px;
        color: #FF0000;
      }
    }

    &-btn{
      width: auto !important;
      max-width: none !important;
      margin-top: 15px;
      float: right;
    }

  }

  &__adress{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px #e4e4e4 solid;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;

    &-content{
      line-height: 1.3;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .cart__btn{
      margin-bottom: 10px;
    }
  }

  &__desires {
    width: 95% !important;
    margin: 0 auto;

    &-wrapper {
      width: 95% !important;
      display: flex;
      flex-flow: wrap row;
      justify-content: center;
      align-items: center;
    }

    &-btn{
      top: -3px;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
    }
  }

  &__agree{
    margin-top: 11px;
    display: inline-block;
    line-height: 1.3;

    & a{
      color: #333;
      text-decoration: underline;
      &:hover{
       color: #000;
      }
    }
  }
}
@media (min-width: 380px) {
  .profile {
    width: 90%;

    &__block {
      width: 350px;
    }

    &__pages {

      & legend{
        margin-bottom: 20px;
      }

      &-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-name {
          width: 170px;
          justify-content: flex-end;
          text-align: right;
        }

        &-field,
        &-list{
          //width: 83.6%;
        }

      }
    }

  }
}
@media (min-width: $phone-landscape) {
  .profile {

    &__tr{
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__td{
      padding: 20px;
      width: 100%;
    }


    &__title {
      font-size: 25px;
    }

    &__block {
      &-title {
        font-size: 22px;
      }
    }

    &__adress{
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .cart__btn,
      &__content{
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }
}
@media (min-width: 543px) {
.profile{
  &__desires{
    &-wrapper {
      justify-content: flex-start;
    }
  }
}
}
@media (min-width: $tablet-portrait){
  .profile{
    &__title{
      text-align: left;
      margin-left: 30px;
    }
  }
}

@media (min-width: $tablet-landscape) {
  .profile {
    flex-flow: nowrap row;
    justify-content: space-around;

    &__block {

      & a {
        line-height: 1.2;

        &:hover{
          color: #FF0000;
        }


        &::before {
          content: "";
          display: block;
          position: absolute;
          height: 1px;
          width: 0;
          background-color: #FF0000;
          -webkit-transition: width .2s ease, left .2s ease;
          transition: width .2s ease, left .2s ease;
          left: 0;
          bottom: -2px;
        }

        &:hover::before {
          width: 100%;
          left: 0;
        }


      }
    }

    &__buttons {

      &-btn {

        &:hover {
          color: #fff;
          border: 1px #ff0000 solid;
          background-color: #ff0000;
        }
      }

    }

    &__desires {
      flex-direction: row;
      justify-content: space-between;

      &-wrapper {
        width: 100% !important;
      }
    }

  }
}

@media (min-width: $desktop) {
.profile {

  &__rightblock {
    margin-left: 30px;
  }

  &__off{
    display: block;
  }
  &__modify{
    width: 100%;
  }

  &__desires {
    width: 100%;
  }
}
}

@media (min-width: 1320px) {
  .profile{
    &__desires {
      padding: 0 20px;
      width: 100% !important;

      &-wrapper {
        width: 100% !important;

      }
    }
  }
}
