.special{

  &__more:active .special__home {
      height: auto;
  }

  &__home{
  display: block;
  width: 95%;
  max-width: 1687px !important;
  margin: 0 auto;
  padding-top: 20px;
  max-height: 603px;
  overflow: hidden;
  }
  &__product{
    margin-right: 0;
  }

  &__title{
    color: #333;
    font-size: 22px;
    padding: 0 0 20px 10px;
    margin-bottom: 20px;
    border-bottom: 1px #e4e4e4 solid;
  }

  &__wrapper{
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }

  &__product{
    border-color: 1px #e4e4e4 solid
  }
}


@media (min-width: 485px) {
  .special{
    &__wrapper{
      justify-content: space-around;
    }
  }
}

@media (min-width: 969px) {
  .special{
    &__wrapper{
      justify-content: space-between;
    }
  }
}

@media (min-width: $bigdesktop) {
  .special {
    &__home{
      width: 100%;
    }
  }
}