.cart{
  width: 90%;
  margin: 0 auto;
  color: #333;

  &__product{
    display: flex;
    flex-flow: wrap row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border-bottom: 1px #e4e4e4 solid;
    padding: 10px;
    margin-bottom: 10px;

    & img{
      height: auto;
      width: auto;
      max-height: 130px;
      max-width: 201px;
      margin: 10px 0;
    }

    &-title{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1.2;
      width: 95%;
      text-align: center;

      & a{
        font-size: 14px;
        color: #333;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
      }
    }

    &-group{
      display: block;
      width: 80%;
      max-width: 700px;
      margin: 0 auto;

      &-title{
        color: #333;
        font-size: 14px;
        margin: 25px 0 5px;
        text-align: center;
      }

      &-line{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      &-field{
        width: 100%;
        background-color: none;
        border: 1px #e4e4e4 solid;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        color: #333;
        height: 30px;
        padding: 6px 8px !important;
        margin: 5px 0 10px;
      }
    }

    &-quantity{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 95%;
      margin: 20px 0;

      &-code{
        font-size: 14px;
        margin-bottom: 10px;
      }

      &-form{
        width: 140px;
        background-color: none;
        border: 1px #e4e4e4 solid;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        color: #333;
        height: 30px;
        padding: 6px 8px !important;
        text-align: center;
        margin: 0 auto 10px;
      }
    }

    &-amount{
      text-align: center;

      &-title{
        margin-bottom: 10px;
        font-size: 14px;


      }

      &-type{
        margin-bottom: 15px;
        font-size: 12px;

        &:last-child{
          margin: 0;
        }
      }

    }
  }

  &__btnwrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }

  &__btn{
    font-family: Verdana, sans-serif;
    font-size: 14px;
    width: 140px;
    max-width: 180px;
    background: none;
    border: 1px #e4e4e4 solid;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #333;
    padding: 8px 10px;
    font-weight: normal;
    text-align: center;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    line-height: 1;

    &-modify1{
      margin: 20px 0 10px;
    }

    &-modify2{
      line-height: 1;
      width: 100%;
      max-width: 185px;
      margin: 0 auto 10px !important;
    }
    &-modify3{
      margin: 0 auto !important;
    }
    &-modify4{
      line-height: 1;
      width: 100%;
      max-width: 185px;
      margin: 0 auto !important;
    }
    &-modify5{
      line-height: 1;
      width: 100%;
      max-width: 185px;
      margin: 0 auto 10px !important;
    }
    
  }

  &__footer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 30px !important;

    &-summa{
      line-height: 1;
      margin: 0 20px 5px 0;
      font-size: 14px;
    }
  }

}

@media (min-width: 450px){
  .cart{
    &__btnwrapper{
      flex-direction: row;
      justify-content: flex-end;
    }

    &__btn{
      &-modify4{
      margin: 0 !important;
      }

      &-modify5{
        margin: 0 10px 0 0 !important;
      }
    }
  }
}
@media (min-width: $phone-landscape) {
  .cart {
    &__product {
      &-title {
        width: 50%;
      }
      &-group {
        position: relative;
        right: 0;
        display: block;
        width: 90%;
        max-width: 700px;
        padding: 0 50px;
        margin: 0;
        float: right;

        &-title{
          text-align: left;
          margin-top: 15px;
        }

        &-field{
          margin: 0 10px 0 0;
        }

        &-line {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

@media (min-width: $tablet-portrait) {
  .cart {
    width: 90%;
    margin: 0 auto;
    color: #333;

    &__product {
      &-quantity{
        width: 30%;
      }

      &-amount{
        width: 30%;
      }
    }
  }
}

@media (min-width: 1024px) {
  .cart{
    width: 100%;
    padding: 0 50px;

    &__product{
      flex-direction: row;
      justify-content: space-around;

      &-title{
        width: 20%;
        & a {

          &:hover {
            color: #000;
          }
        }
      }

      & img{
        margin: 0;
      }

      &-quantity{
        width: 20%;
        margin: 10px 0 0;
      }

      &-amount{
        width: 20%;
        text-align: center;

        &-title{
          margin-bottom: 10px;
          font-size: 14px;


        }

        &-type{
          margin-bottom: 15px;
          font-size: 12px;

          &:last-child{
            margin: 0;
          }
        }

      }
    }

    &__btn {

      &-modify1 {
        margin: 0;
      }

      &:hover {
        color: #fff;
        border: 1px #ff0000 solid;
        background-color: #ff0000;
      }
    }
  }
}