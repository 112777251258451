.login{
  &__popup{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-wrapper {
      position: relative;
      min-height: 390px;
      width: 400px;
      color: #333;
      background: #fff;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(200,200,200,0.8);
      border: 1px #e4e4e4 solid;
    }

    &-notification{
      position: absolute;
      top: 59px;
      z-index: 9999;
      width: 100%;
      display: none;

      &-error{
        font-size: 13px;
        color: #fff;
        background-color: #fd3d3d;
        padding: 10px;
      }

      &-success{
        font-size: 13px;
        color: #fff;
        background-color: #42cd53;
        padding: 10px;
        text-align: center;
      }
    }

    &-body{
      padding: 9px 19px 0;
    }
    &-email{
      display: none;
    }

    &-title{
      padding: 19px;
      color: #333;
      font-size: 20px;
      border-bottom: 1px solid #e4e4e4;;
      font-family: "Open Sans", sans-serif;
    }

    &-text{
      font-size: 14px;
      padding: 20px 0 20px;
      text-align: center;
      color: #8a8a8a;
    }

    .dd-select {
      width: 60px;
      background: none;
      position: relative;
      cursor: pointer;
      background: none !important;
      border: none !important;
    }

    .dd-option-selected{
      display: none !important;
    }

    .tel{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &-field{
      width: 100%;
      background: none;
      border-bottom: 1px #e4e4e4 dashed;
      border-top: none;
      border-left: none;
      border-right: none;
      color: #333;
      height: 30px;
      padding: 6px 8px !important;
    }

    &-changenum{
      text-decoration: underline;
      color: #333;
      font-size: 14px;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      text-align: center;
      display: block;
      margin-top: 20px;

      &:hover{
        color: #ff0000;
      }
    }

    &-reglink{
      padding: 20px 0;
      text-align: center;

      & a{
        text-decoration: none;
        color: #333;
        font-size: 14px;
        border-bottom: 1px #8a8a8a dashed;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;

        &:hover{
          color: #ff0000;
        }
      }
    }
  }

  &__btn{
    font-family: Verdana, sans-serif;
    font-size: 14px;
    width: 100%;
    max-width: 180px;
    background: none;
    border: 1px #e4e4e4 solid;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #333;
    padding: 8px 5px;
    font-weight: normal;
    text-align: center;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    line-height: 1;
    display: block;
    margin: 0 auto;

    &:hover {
      color: #fff;
      border: 1px #ff0000 solid;
      background-color: #ff0000;
    }
  }
}
@media (min-width: $phone-landscape) {

}
@media (min-width: $tablet-portrait) {

}
@media (min-width: $tablet-landscape) {

}
@media (min-width: $desktop) {

}