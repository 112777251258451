.subcategory{
  width: 100%;
  display: none;
  margin-bottom: 20px;

  & ul{
    & li{
      & a{
        display: block;
        color: #333333;
        font-size: 14px;
        line-height: 1.2;
        padding: 9px 0 9px 5px;
        border-bottom: 1px #4e4e4e dashed;
        position: relative;
        -webkit-transition: color .2s ease;
        transition: color .2s ease;

        &::before {
          content: "";
          display: block;
          position: absolute;
          height: 1px;
          width: 0;
          background-color: #FF0000;
          -webkit-transition: width .2s ease, left .2s ease;
          transition: width .2s ease, left .2s ease;
          left: 0%;
          bottom: -1px;
        }
      }

    }
  }
}

@media (min-width: $phone-landscape) {

}

@media (min-width: $tablet-portrait) {

}

@media (min-width: $tablet-landscape) {

}

@media (min-width: 1200px) {
  .subcategory{
    display: block;

    & ul{
      & li{
        & a{

          &:hover{
            color: #ff0000;
          }

          &:hover::before {
            width: 100%;
            left: 0;
          }
        }

      }
    }
  }
}