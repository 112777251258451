.contacts{
  padding: 0 10px;
  color: #333;

  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__list,
  &__schedule{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    & span{
      display: block;
      line-height: 1.5;
    }

    & a{
      color: #333;
    }
  }

  &__schedule {
    width: 80%;
  }

  &__title{
    font-size: 20px;
    margin:25px 0 10px;

    &-modify{
      width: 100%;
      text-align: center;
    }
  }

  &__list{
    display: block;

    &-phones,
    &-email{
      height: 80px;
      width: 230px;
      margin: 0 auto;
      text-align: right;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;


      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 60px;
        height: 60px;
      }

      & span{
        font-size: 16px;
      }
    }

    &-phones {
      &::before {
        background: url(../images/icons/functional/icons-connectivity-black.svg);
        background-size: 60px 60px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &-email{
      &::before{
        background: url(../images/icons/functional/icons-email-black.svg);
        background-size: 60px 60px;
      }

      & span{
        line-height: 60px;
      }
    }

    &-social{
      width: 90%;
      max-width: 460px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 20px auto 0;

      & a{
        margin: 0 0 15px 0;
        height: 40px;
        width: 40px;
        background-size: 40px 40px;
      }
    }
  }

  &__backform{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;

    &-form,
    &-info{
      width: 100%;
      padding: 0 20px;
      line-height: 1.5;
    }

    &-form{
      margin-bottom: 20px;
    }

    &-form input,
    &-form textarea{
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 5px;
      border-radius: 4px;
      border: 1px #e4e4e4 solid;
    }
  }
}
@media (min-width: $phone-landscape) {
.contacts{

  &__title{
    font-size: 22px;
  }

  &__list{

    &-phones{
      width: 280px;

      & span{
        font-size: 20px;
      }

      &::before {
        width: 70px;
        height: 70px;
        background-size: 70px;
      }

    }

    &-email{
      width: 280px;

      &::before {
        width: 70px;
        height: 49px;
        background-size: 70px 49px;
      }

      & span{
        font-size: 20px;
        line-height: 70px;
      }
    }

    &-social {
      width: 80%;

      & a {
        margin: 0 0 15px 0;
        height: 60px;
        width: 60px;
        background-size: 60px 60px;
      }
    }
  }
}
}
@media (min-width: $tablet-portrait) {
.contacts{
  padding: 0 20px;
}
}
@media (min-width: $tablet-landscape) {
  .contacts{
    &__wrapper {
      flex-direction: row;
      justify-content: space-around;
    }

    &__title {
      &:first-child {
        margin-top: 0;
      }
    }

    &__list,
    &__schedule{
      width: 48%;
    }

    &__backform{
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      &-form,
      &-info{
        width: 48%;
        padding: 0 50px;
        line-height: 1.5;
      }

      &-form{
        margin-bottom: 0;
      }
    }
  }
}