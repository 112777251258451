.socialfooter{
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;

  &__telegram,
  &__facebook,
  &__vk,
  &__instagram,
  &__youtube{
    display: block;
    height: 35px;
    width: 35px;
    background-size: 35px 35px;
    margin-right: 10px;
    transition: opacity .3s ease;
  }

  &__youtube{
    margin-right: 0 !important;
  }

  &__telegram{background-image: url('../images/icons/social/social-footer-telegram.svg');}
  &__facebook{background-image: url('../images/icons/social/social-footer-facebook.svg');}
  &__vk{background-image: url('../images/social/footer-vk.png');}
  &__instagram{background-image: url('../images/icons/social/social-footer-instagram.svg');}
  &__youtube{background-image: url('../images/icons/social/social-footer-youtube.svg');}

}

@media (min-width: $tablet-portrait) {
  .socialfooter{
    &__telegram:hover,
    &__facebook:hover,
    &__vk:hover,
    &__instagram:hover,
    &__youtube:hover{
      opacity: 0.5;
    }
  }
}