.rating{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fa{
  display:inline-block;
  font:normal normal normal 14px/1 FontAwesome;
  font-size:inherit;
  text-rendering:auto;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}

.fa-stack{
  position:relative;
  display:inline;
  width:2em;
  height:2em;
  line-height:2em;
  vertical-align:middle;
  font-size: 8px;
  margin-right: 4px;

  //&:first-child{
  //  margin-right: 0;
  //}
}

.fa-stack-1x,.fa-stack-2x{
  position:absolute;
  left:0;
  width:100%;
  text-align:center
}

.fa-stack-1x{
  line-height:inherit
}

.fa-stack-2x{
   font-size:2em
 }

.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-stack-exchange:before{
  content:"\f18d";
}
.fa-star:before{
  content:"\f005";
}
.fa-star-o:before{
  content:"\f006";
}
.fa-star-o {
  color: #999;
  font-size: 18px;
}
.fa-star {
  color: #ff0000;
  font-size: 18px;
}
.fa-star + .fa-star-o {
  color: #e60000;
}

.fa-like:before{
  content:"\f087"
}
.fa-dislike:before {
  content: "\f088";
}
