/*---------- backgrounds ----------*/
$background-main: #fff;
$background-content: #fff;
$background-content-category: #fff;
$background-content-product: #fff;
$background-header-gradient0: rgba(14,14,14,0.9);
$background-header-gradient100: #0e0e0e;
$background-products: #fff;
/*---------- /backgrounds ---------*/



/*---------- Titles ----------*/
$color-h1-product: #333333;
$color-h2-name-product: rgba(0,0,0,0.9);
$color-title-options-product: #333333;
$color-product-review-text: #333333;
$color-page-side-title: #333333;
$color-page-side-filter-title: #333333;
$color-products-title: #333333;
$color-products-title-hover: #ff0000;
/*---------- /Titles ---------*/

/*---------- Texts ----------*/
$color-text-shortspec-product: #333333;
$color-text-deliveryitem-product: #333333;
$color-text-description-product: #333333;
$color-page-side-text: #333333;
/*---------- /Texts ---------*/


/*---------- Borders ----------*/
$color-border-h1-product: #e4e4e4;
$color-border-panel-product: #e4e4e4;
$color-border-table-dashed-product: #e4e4e4;
$color-border-images-second-product: #e4e4e4;
$color-border-active-panel-product: #ff0000;
$color-border-product-review: #e4e4e4;
$color-border-product-review-input: #e4e4e4;
$color-border-product-review-buttons: #e4e4e4;
$color-border-product-review-buttons-hover: #ff0000;
$color-border-products: #e4e4e4;
/*---------- /Borders ---------*/

$color-links-panel-product: #333333;
$color-links-active-panel-product: #000;

$color-buttons-buy-after-product: #ff0000;
$color-buttons-product-review: #333333;
$color-buttons-products: #333333;
$color-buttons-products-buy: #fff;