.product{

  &__brand{
    display: none;
    color: #333;
    transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;

    &:hover{
      color: #ff0000
    }
  }

  &__bar{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0 15px;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;

    &-left{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      & span{
        margin: 0 5px;
      }
    }
  }
  
  &__left{
    width: 100%;
    margin-right: 0;

    &-images{
      height: 100%;
      margin: 15px auto 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & img{
        max-width: 100%;
      }

      #image-second, #image-third, #image-fourth{
        display: none;
      }

      &-main{
        width: auto;
        height: auto;
        margin: 30px 0 10px 0;
        float: none;
        position: relative;
      }


      &-bar{
        display: flex;
        flex-flow: wrap row;
        justify-content: center;
        width: 100%;
        margin-bottom: 5px;
      }

      &-second{
        height: 75px;
        width: 75px;
        border: 1px $color-border-images-second-product solid;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        margin:0 5px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 3px;
        overflow: hidden;

        & img{
          width: auto;
          height:auto;
          max-width: 65px;
        }

        &:last-child{
          margin-right: 0;
        }
      }

    }

  }
  
  &__right{
    width: 100%;
  }
  
  &__title{
    font-size: 24px;
    font-family: "ProximaNova", sans-serif;
    color: $color-h1-product;
    font-weight: normal;
    text-align: center;
    //border-bottom: 1px $color-border-h1-product solid;
    margin: 30px 0 15px;
    line-height: 1.3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__str{
    &-stock{
      line-height: 1;
      font-weight: bold;
    }

    &-instock{
      color: #00ad00;
      background-color: #f1f8eb;

      &:before{
        content: "";
        height: 18px;
        width: 18px;
        margin-right: 10px;
        background-size: 18px 18px;
        background-image: url(../images/icons/functional/icons-availability-available.svg);
      }
    }

    &-waitstock{
      color: #c48c08;
      background-color: #fffca6;

      &:before{
        content: "";
        height: 18px;
        width: 18px;
        margin-right: 10px;
        background-size: 18px 18px;
        background-image: url(../images/icons/functional/icons-availability-wait.svg);
      }
    }

    &-outstock{
      color: #3F4E5D;
      background-color: #f4f4f4;

      &:before{
        content: "";
        height: 20px;
        width: 20px;
        margin-right: 10px;
        background-size: 20px 20px;
        background-image: url(../images/icons/functional/icons-availability-not-available.svg);
      }
    }

    &-red{
      color: #e60418;
    }

    &-yellow{
      color: #e6df00;
    }

    &-instock,
    &-outstock{
      font-size: 14px;
      padding: 5px 10px 5px 5px;
      line-height: 1;
      border-radius: 13px;
      -webkit-border-radius: 13px;
      border-top-right-radius: 8px;
      -webkit-border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      -webkit-border-bottom-right-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__info{
    width: 100%;
    padding-top: 10px;

    &-price{
      display: block;
      color: #333;
      text-align: flex-start;
      font-family: "Century Gothic", sans-serif;
      margin: 10px 0 0;

      &-symbol{
        font-style: normal;
        font-size: 21px;
        line-height: 1;
      }

      &-real{
        width: 100% !important;
        height: 35px !important;
        font-size: 28px !important;
        line-height: 1.419 !important;
        color: #333 !important;
      }

      &-old{
        width: 100% !important;
        height: 20px !important;
        font-size: 18px !important;
        color: #babbbf !important;
        text-decoration: line-through !important;
        margin-top: 10px;

        .product__info-price-symbol{
          font-size: 14px;
        }
      }

      &-new{
        width: 100% !important;
        height: 35px !important;
        font-size: 28px !important;
        color: #ff0000 !important;
        font-weight: bold !important;
      }

    }


    &-general{
      width: 100%;

      &-data{
        width: 100%;
        font-size: 14px;

        & table{
          width: 100%;

          & tr{
            width: 100%;
            border-bottom: 1px rgba(131,131,131,0.2) dashed;

            & td{
              padding: 10px 0;
            }

            & .title{
              width: 35%;
              padding-left: 20px;
            }

            & .value{
              width: 65%;
            }
          }
        }
      }

      &-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        & span{
          display: inline-block;
        }
      }

      &-info{
        text-align: center;
        padding: 10px 0 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & span{
          font-size: 14px;
          color: $color-text-shortspec-product;
          font-family: ProximaNova, sans-serif;
          display: inline-block;
          width: auto;

          &:last-child{
            margin: 0;
          }
        }
        &-one{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }

      &-properties{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        width: 100%;

        &-wrapper{
          width: 100%;
        }

        .hpm_pp_select{
          width: 100%;
        }

        .hpm-group{
          margin-bottom: 10px;
        }

        & .options{
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 10px;
          margin-top: 10px;

          & label,
          &-title{
            font-size: 14px;
            color: $color-title-options-product;
            margin-bottom: 5px;
            display: block;
            text-align: center;
          }

          & select,
          & input{
            width: 150px;
            background-color: #0e0e0e;
            border: 1px #838383 solid;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            color: #f6f6f6;
          }
          & select{
            padding: 5px;
            /*for WebKit*/
            -webkit-appearance: none;
            /* for FF */
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
            /* for IE */
            -ms-appearance: none;
            appearance: none!important;
            background-image: url('../images/select-arrow.png');
            background-size: 11px;
            background-position: 130px center;
            background-repeat: no-repeat;
            margin: 0 auto;
          }
          & input{
            padding: 5px;
            text-align: center;
            line-height: 1.2;
            margin: 0 auto;
          }

          &-radio{

            & input {
              display: none !important;
            }

            & input:checked + label,
            & input:checked + img {
              border: 1px solid #fff;
              color: #fff;
              //background-color: red;
              font-weight: bold;
            }

            & label{
              display: block;
              border: 1px #838383 solid;
              height: 25px;
              min-width: 25px;
              margin-bottom: 5px;
              margin-right: 5px;
              text-align: center;
              transition: border .2s ease;

              & a{
                color: #cfcfcf;
              }

              & b{
                display: block;
                margin: 0 auto;
                padding: 5px;
                line-height: 0.9;
              }
              & i {
                font-style: none;
                padding: 5px 2px 5px 0;
                line-height: 0.7 !important;
              }

              & span{
                line-height: 0.9 !important;
              }
            }

            & .hcol-name_exp_last {
              color: #cfcfcf;
              display: flex !important;
              flex-direction: row;
              justify-content: center;
              margin-bottom: 5px;
              margin-right: 5px;
              border: 1px #909090 solid;
              padding: 6px 0;
              font-size: 12px;
              min-width: 26px;

              &:hover{
                border: 1px #cfcfcf solid !important;
                color: #fff;
              }

            }

            .active .hcol-name_exp_last{
                border: 1px #fff solid !important;
                padding: 6px 6px;

                &:after{
                  content:"\f147";
                  font-family: dashicons;
                  height: auto !important;
                  line-height: 0.6 !important;
                  color: #fff;
                  position: relative;
                  font-size: 20px;
                  width: 16px;
                }
              }

            & .disabled{
              opacity: 0.5;

            }


            .hpm-item{
              margin-right: 7px !important;
              margin-bottom: 7px !important;

              &:hover{
                cursor: pointer;
              }

              &:last-child {
                margin-right: 0 !important;
              }
            }

            .hcol-image,
            .hcol-attribute{
              height: 26px !important;
              border: none !important;
              overflow: hidden;
              //background-color: #312f2f;

            }

            .hcol-image{
              width: 19px !important;
              -webkit-border-radius: 50px;
              -moz-border-radius: 50px;
              border-radius: 100px;
              padding: 4px 0;
            }

            .active .hcol-image{
              width: 46px !important;
              padding: 2px 3px;
              &:after {
                top: 1px;
              }
            }

            .hcol-attribute{
              color: #333;
              padding: 3px 5px;
              border: 1px #e4e4e4 solid !important;
              line-height: 1;
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;

              &:hover{
                border: 1px #ff0000 solid !important;
                color: #ff0000;
              }
            }

            .active .hcol-attribute{
              padding: 0 5px;
              height: 26px !important;

              &:after {
                top: 5px;
              }
            }

            .active .hcol-image,
            .active .hcol-attribute{
              border: 1px #838383 solid !important;
              line-height: 1.6;

              &:after{
                content:"\f147";
                font-family: dashicons;
                height: auto !important;
                width: auto !important;
                line-height: 0.6 !important;
                color: #333;
                position: relative;
                font-size: 21px;
              }

            }

                       & img{
              width: 18px;
              height: 18px;
              margin: 0 auto;
              margin-right: -1px;
            }
          }


          &__table{

            &-item{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              font-size: 12px;
              color: #333;
              margin-bottom: 9px;
              border-bottom: 1px #666666 dashed;
              //padding-bottom: 9px;
              cursor: pointer;
              position: relative;
              padding: 0 2px 9px;

              &:before {
                content: '-';
                display: block;
                margin-right: 5px;
              }

              &::after {
                content: "";
                display: block;
                position: absolute;
                height: 1px;
                width: 0;
                background-color: #ff0000;
                color: #fff;
                -webkit-transition: width .2s ease, left .2s ease;
                transition: width .2s ease, left .2s ease;
                left: 0;
                bottom: -1px;
              }

            }

            .active-link{
              height: 15px;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }

            .active{
              .active-link::after{
                content: "\f147" !important;
                font-family: dashicons;
                color: #333;
                font-size: 22px;
                height: 15px;
                line-height: 0.9;
                width: 13px;
                display: flex;
                justify-content: center;
                margin-left: 5px;
              }
            }

            .active::after{
              width:100%;
            }

            & .disabled{

              &::before{
                opacity: 0.5;
              }

              .active-link,
              .hpm-item{
                opacity: 0.5;
              }
            }

          }



        }

        & .radio{
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          margin: 10px 0 0;
        }

      }

      &-danger{
        font-size: 14px;
        color: #FF0000;
        font-family: "Open Sans", sans-serif;
        padding: 10px 0 0;
      }

      &-minquantity{
        font-size: 14px;
        color: #ff5a00;
        font-family: "Open Sans", sans-serif;
        padding:0 15px 20px;
        text-align: center;
      }

      &-green{
        font-size: 14px;
        color: #31b900;
        font-family: "Open Sans", sans-serif;
        padding:0 15px 20px;
        text-align: center;
      }


      &-buttons{
        height: auto;
        width: 100%;
        margin: 10px 0;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;


        & .system{
          width: 150px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        &-social{
          margin-top: 10px;
        }

        &-modify{
          height:32px;
          background-size: 54px 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: auto;
          font-size: 13px;

          &::before{
            margin-right: 10px;
          }

          & span{
            font-size: 13px;
            margin: 0;
            padding: 0;
            border-bottom: 1px #8a8a8a dashed;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
          }

        }
      }

    }

    &-deliveryandpay{
      width: 100%;
      font-family: ProximaNova, sans-serif;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 30px;

      &-block{
        width: 100%;

        &:last-child{
          margin-top: 30px;
        }
      }

      &-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & b{
          min-width: 92px;
          text-align: right;
        }

        & .free{
          color: green;
          font-size: 14px;
        }
      }


      &-title{
        font-size: 20px;
        color: #333;
        text-align: center;
        margin: 5px 0 15px;
      }

      & span{
        color: $color-text-deliveryitem-product;
        font-size: 13.5px;
        margin: 0 10px 15px 0;
        line-height: 1.4;
        display: flex;
        flex-direction: row;
        align-items: center;

        & i{
          margin-right: 5px;
        }

        & a {
          color: #333;
          display: inline-block;
          position: relative;
          line-height: 1.5;

          &::before {
            content: "";
            display: block;
            position: absolute;
            height: 1px;
            width: 0;
            background-color: #cfcfcf;
            -webkit-transition: width .2s ease, left .2s ease;
            transition: width .2s ease, left .2s ease;
            left: 0;
            bottom: 0;
          }

        }
      }

      &-concretization{
        color: #cfcfcf;
        font-size: 9px;
        line-height: 1.3;
        bottom: 6px;
        position: absolute;
      }
    }

  }
  &__buy,
  &__buy-1click{
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
  }

  &__buy{
    position: relative;
    width: 49%;
    height: 45px;
    background: rgb(0,0,0);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 20px;
    font-family: ProximaNova, sans-serif;
    text-align: center;
    font-weight: normal;
    padding: 0 13px;
    margin: 5px 5px 0 0 ;
    border: none;

    & span{
      color: #f6f6f6;
      font-size: 20px;
    }
  }

  &__buy-1click{
    position: relative;
    width: 49%;
    height: 45px;
    border: 1px rgb(255,0,0) solid;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 20px;
    font-family: ProximaNova, sans-serif;
    text-align: center;
    font-weight: normal;
    padding: 0 13px;
    margin-top: 5px;
    background: none;
    margin-left: 5px;

    & span{
      color: rgb(255,0,0);
      font-size: 16px;
    }
  }

  &__tabs{

    &-panel {
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      min-height: 40px;
      width: 100%;
      border-bottom: 1px $color-border-panel-product solid;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px 0 15px;
      text-align: center;

      &-buttons{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
      }

      & a {
        color: $color-links-panel-product;
        padding: 12px;
        transition: all 0.3s ease;
      }

      &-tabs{
        display: flex;
        flex-flow: wrap;

      }
      &-footer{
        margin: 10px 0 10px;
        border-top: 1px #e4e4e4 solid;
      }

      & .active{
        color: $color-links-active-panel-product;
        border-bottom: $color-border-active-panel-product 1px solid;
      }

      &-links{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        top: 1px;
      }


    }

    &-colums{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    #characteristics, #tab-sizes{
      display: none;
      width: 100%;
    }

    &-content {
      color: $color-text-description-product;
      font-size: 14px;
      font-family: "ProximaNova",sans-serif;
      line-height: 1.5;
      width: 100%;

      & h3{
        font-size: 18px;
        margin: 20px 0 10px;
      }

      & p{
        text-indent: 20px;
        margin-bottom: 10px;
        text-align: justify;
      }

      & ul,ol{
        margin-bottom: 10px;
        & li{
          margin-left: 35px;
        }
      }


      & ul{
        list-style: disc !important;
      }

      & ol{
        list-style: decimal !important;
      }

      & b{
        font-weight: bold;
      }

      & table{
        width: 100%;

        & tr{
          width: 100%;
          border-bottom: 1px rgba(131,131,131,0.2) dashed;

          &:last-child{
            border: none;
          }

          & td{
            padding: 10px 10px 10px 0;
            &:last-child{
              padding: 10px 0;
            }
          }

          & .title{
            width: 35%;
            padding-left: 20px;
          }

          & .value{
            width: 65%;
          }
        }
      }

      &-sizes{
        width: 100%;

        & tr{
          width: 100%;
          border-bottom: 1px $color-border-table-dashed-product dashed;
          min-width: 150px;

          &:last-child{
            border: none;
          }

          & td{
            padding: 10px 10px 10px 0 !important;
            text-align: center;

            &:last-child{
              padding: 10px 0 !important;
            }
          }

          & .title{
            width: 35%;
            padding-left: 20px;
          }

          & .value{
            width: 65%;
          }
        }

        & tr:hover {
          background-color: #ececec;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
        }
      }

      &-title{
        font-size: 18px;
        padding: 0 20px 10px;
        font-weight: bold;
        text-align: center;
        font-family: "ProximaNova",sans-serif;

        & span{
          display: block;
          color: $color-h2-name-product;
          font-size: 16px;
        }
      }

      &-subtitle{
        font-size: 17px;
        padding: 10px 20px 10px;
        color: #8a8a8a;
        text-align: left;
        font-family: "ProximaNova",sans-serif;
      }

      &-image{
        width: 100%;
        height: auto;
        display: block;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
      }
    }
  }
}
.thumbnails-line {

  a:first-child { .product__tabs-content-image{
    -webkit-border-top-left-radius: 8px !important;
    -webkit-border-top-right-radius: 8px !important;
    -moz-border-top-left-radius: 8px !important;
    -moz-border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;}
  }

  a:last-child .product__tabs-content-image{
    -webkit-border-bottom-left-radius: 8px !important;
    -webkit-border-bottom-right-radius: 8px !important;
    -moz-border-bottom-left-radius: 8px !important;
    -moz-border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  a .product__tabs-content-image{
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }
}
@media (min-width: 420px) {
  .product {

    &__bar{
      &-left{
        margin-bottom: 0;
      }
    }

    &__info {

      &-general {

        &-info{
          text-align: left;
          padding-top: 10px;
        }

        &-buttons{
          height: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          & .system{
            width: 80px;
          }

          &-modify{
            height:20px;
            background-size: 34px 20px;
          }

          &-social{
            margin: 0;
          }
        }


        &-properties {
          & .options{
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 550px) {
  .product{

    &__tabs{
      &-panel a{
        margin-right: 20px;
      }
    }
    &__info{

      &-deliveryandpay {
        flex-direction: row;
        justify-content: space-between;

        &-block {
          width: 50%;

          &:first-child {
            padding-right: 10px;
            border-right: 1px #e4e4e4 solid;
          }

          &:last-child {
            padding-left: 10px;
            margin-top: 0;
          }
        }
      }

      &-price{
        width: 100%;
      }

      &-general{
        &-info{

          & span {
            margin-bottom: 10px;
          }
          &-one {
            margin-top: 20px;
          }
        }

        &-wrapper {
          flex-direction: row;
          justify-content: space-between;
        }

        &-properties{
          width: 50%;
          text-align: left;


          .radio{
            justify-content: flex-start;
          }

          .radio{
            margin: 10px 10px 0;
          }

          & .options{
            &__table{
              margin: 10px 10px 0;
            }

            &-title{
              text-align: left;
            }
          }
        }
        &-data{
          width: 50%;
        }
      }
    }
    &__left {
      &-images {
        flex-direction: row;
        align-items: center;

        &-main {
          float: left;
          margin: 30px 10px 0 0;

          & img{
            width: 100%;
            max-width: 610px;
          }
        }

        &-bar {
          flex-direction: column;
          justify-content: center;
          width: 75px;
        }

        &-second {
          margin: 0 0 10px 0;
        }
      }
    }
  }
}

@media (min-width: 650px) {
.product{

  &__tabs{
    &-panel{
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      padding: 0 20px;

      &-links{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }
}
}

@media (min-width: $tablet-portrait) {
  .product{

    &__title{
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 10px;

      & h1{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__tabs{

      &-colums{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

@media (min-width: 792px) {
  .product{
    &__info {

      &-deliveryandpay {

        & span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .product{

    &__title{
      max-height: 70px;
      margin-bottom: 0;
    }

    &__brand{
      display: block;
    }

    &__left{
      width: 50%;
      margin-right: 20px;

      &-images{
        width: auto;
        height: auto;
        flex-direction: column;
        justify-content: center;

        & img{
          max-height: 600px;
          max-width: 100%;
        }

        &-main{
          float: none;
          margin: 0 0 10px 0;
        }

        &-bar{
          display: flex;
          flex-flow: wrap row;
          justify-content: center;
          width: 100%;

          & a{
            margin-bottom: 10px;
          }
        }

        &-second{
          margin: 0 10px 0 0;

          &:last-child{
            margin-right: 0;
          }
        }

      }

    }

    &__right{
      width: 50%;
    }

  }
}

@media (min-width: 1150px) {
  .product{
    &__left{
      margin-right: 20px;

      &-images{
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0;
        padding-top: 20px;

        &-main {
          float: none;
          margin: 0 auto;
          padding: 0 20px;
          order: 1;

          .product-sticker{
            top:0;
            left:0;
            right:0;
            bottom:0;
          }
          .product-sticker .text.oval, .category-sticker .text.oval, .product-status.text.oval, .category-status.text.oval{
            font-size: 16px;
            height: 30px;
          }

          & img{
            max-width: 690px;
          }
        }

        & img{
          max-width: 83%;
        }

        &-bar {
          flex-direction: column;
          justify-content: center;
          width: 90px;
          order: 0;
          max-height: 410px;
          overflow: hidden;
        }

        &-second{
          margin: 0 0 10px 0;

          &:last-child{
            margin-bottom: 0;
          }
        }

      }

    }


    &__title{
      font-size: 28px;
    }

    &__info{

      &-general {
        width: 100%;

        &-price{
          width: 100%;
        }

        &-properties{
          & .options{
            &__table {
              &-item:hover {
                color: $color-buttons-buy-after-product;
                opacity: 1;

                &::after {
                  width: 100%;
                }
              }
            }
          }
        }

        &-buttons{
          &-modify:hover span{
            color: $color-products-title-hover;
            border-bottom: 1px $color-products-title-hover dashed;
          }
        }
      }

      &-deliveryandpay{

        & span{
          & a {
            color: #333;
            &:hover::before {
              width: 100%;

            }
            &:hover,
            &.visited:hover {
              color: #000;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &__tabs{

      &-panel {
        flex-direction: row;
        justify-content: space-between;

        & a:hover{
          border-bottom: $color-border-active-panel-product 1px solid;
          color: #ff0000;
        }

        &-tabs {
          flex-flow: nowrap;
          & a:hover {
            border-bottom: $color-border-active-panel-product 1px solid;
            color: #ff0000;
          }
        }
      }


    }

    &__buy,
    &__buy-1click{

      &:hover{
        background-color: rgba(255,0,0,1);

        & span{
          color: #fff;
        }
      }
    }

  }
}
@media (min-width: 1900px) {
  .product{
    &__tabs {

      &-panel {

        &-buttons{
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
        }
      }
    }
    &__info{
      &-general{
        width: 100%;

        &-buttons{
          &-modify{
            height: 20px;
            background-size: 34px 20px;
          }
        }
      }
    }
    &__left {
      width: 50%;

      &-images {

        &-main {
          order: 1;

          & img{
            max-height: 700px;
            max-width: 690px;
          }
        }

        &-bar {
          width: 90px;
          order: 0;
        }

        &-second {
          margin: 0 0 10px 0;
        }
      }
    }
    &__right{
      width: 50%;
    }
  }
}