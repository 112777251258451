.sitemap{

  &__category,
  &__pages{
    width: 100%;
    text-align: center;

    & a{
      color: #cfcfcf;
      line-height: 1.2;
    }

    & ul {
      padding: 0 !important;
      margin: 0 !important;

      & li {
        list-style-type: disc;
        color: #cfcfcf;
        margin: 0 !important;

        & li{
          margin: 0 0 0 10px !important;
        }

        & ul li {
          list-style-type: circle;
          margin: 0 0 0 10px !important;

          & ul li {
            list-style-type: square;
            margin: 0 0 0 10px !important;
          }
        }
      }
    }
  }
}
@media (min-width: $phone-landscape) {

}
@media (min-width: $tablet-portrait) {
  .sitemap{
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &__category,
    &__pages{
      width: auto;
      text-align: left;
    }

    & a:hover{
      text-decoration: underline;
    }
  }
}
@media (min-width: $desktop) {

}