.category{
  display: none;
  height: 45px;
  width: 100%;
  position: relative;
  z-index: 3;

  &__items{
    display: none;
    flex-direction: row;
    justify-content: center;

    & li{
      display: inline-block;

      & a{
        font-size: 16px;
        font-family: Verdana, sans-serif;
        color: #fff;
        text-align: center;
        padding: 12px 30px;
        line-height: 2.7;
        -webkit-transition:color .3s ease;
        transition:color .3s ease;
        cursor: pointer;
      }

    }

    &-noactive{
      font-size: 16px;
      font-family: Verdana, sans-serif;
      text-align: center;
      padding: 12px 60px;
      line-height: 2.7;
      cursor: pointer;
      color: #8c8c8c;
      position: relative;
      z-index: 3;

      &:before {
        content:attr(data-description);
        box-sizing:border-box;
        display:block;
        background:rgba(0,0,0,.7);
        color:white;
        padding:10px;
        position:absolute;
        left:50%;
        top:52px;
        margin-left:-100px;
        width:200px;
        line-height:10px;
        border-radius:5px;
        opacity:0;
        transition:.3s ease;
        font-size: 11px;
      }

      &:after {
        content:'';
        display:block;
        position:absolute;
        top:44px;
        left:50%;
        margin-left:-8px;
        height:0;
        width:0;
        border-left:8px solid transparent;
        border-right:8px solid transparent;
        border-bottom:8px solid rgba(0,0,0,.7);
        transition:.3s ease;
        opacity:0;
      }

      &:hover:before {
        opacity:1;
        top:52px;
      }

      &:hover:after {
        opacity:1;
        top:44px;
      }
    }

    &-overlay{
      opacity: 0;
      overflow: hidden;
      height: 0;
      background-color: rgba(0,0,0,0.5);
    }

    &-submenu{
      width: 95%;
      max-width: 1725px;
      position: absolute;
      padding-top: 3px;
      left: 0;
      right: 0;
      opacity: 0;
      overflow: hidden;
      color: #333;
      margin: 0 auto;
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease;
      height: 0;

      &-wrapper{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        height: auto;
        width: 100%;
        background-color: rgb(255,255,255);
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        padding-bottom: 25px;


        .subblock{
          width: 305px;
          height: auto;
          padding: 0 20px;

          & a{
            color: #4d4d4d;
            display: block;
            font-size: 14px !important;
            border-bottom: 1px #e4e4e4 solid;
            line-height: 1.7;
            text-align: left;
            padding: 5px 5px;

            &:last-child{
              border: none;
            }
          }

          &__noactive{
            color: #bdbaba !important;
          }

          &__title{
            font-size: 19px;
            margin: 20px 0 10px;
            text-align: center;
          }

          &:last-child{
            border: none;
          }
        }
      }
    }
  }

}

@media (min-width: $desktop) {

    .category__items li:hover + .category__items-overlay{
    height: 100%;
    overflow: visible;
    opacity: 1;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .category {
    display: block;
    &__items {
      display: flex;

      & li {
        & a {
          position: relative;
          z-index: 3;

          &:hover{
            color: #FF0000;
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            height: 3px;
            width: 0;
            background-color: #FF0000;
            -webkit-transition: width .2s ease, left .2s ease;
            transition: width .2s ease, left .2s ease;
            left: 50%;
            bottom: -2px;
          }

          &:hover::before {
            width: 100%;
            left: 0;
          }
        }

        &:hover {
          .category__items-submenu {
            display: block;
            overflow: visible;
            opacity: 1;
            z-index: 25;
            height: 100%;
          }
        }
      }

      &-submenu{
        .subblock{

          a::before {
            content: "";
            display: block;
            position: absolute;
            height: 1px;
            width: 0;
            background-color: #FF0000;
            -webkit-transition: width .2s ease, left .2s ease;
            transition: width .2s ease, left .2s ease;
            left: 0;
            bottom: -1px;
          }

          a:hover::before{
            width: 100%;
          }

          a:hover{
            color: #ff0000 !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .category {
    &__items {
      & li {
        & a {
          padding: 12px 50px;
        }
      }
    }
  }
}