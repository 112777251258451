.order{
  width: 100%;

  &__item {
   display: flex;
   flex-flow: wrap row;
   justify-content: space-between;
   width: 100%;
   padding: 10px 20px 10px;
   margin-bottom: 20px;
   font-family: Arial, sans-serif;
   font-weight: normal !important;
   color: #333;
   font-size: 14px;

    &-modifyup{
      margin-top: 30px;
    }

    &-title {
     width: 100%;
     font-size: 26px;
     font-family: "Century Gothic", sans-serif;
     border-bottom: 1px #e4e4e4 solid;
     padding-bottom: 10px;
     margin-bottom: 10px;
     text-align: center;
   }

    &-block{

     &-left,
     &-right{
       width: 100%;

       & span{
         display: block;
         line-height: 1.5;
       }
     }
   }
 }

  &__modifysumma{
    padding-top: 15px;
    margin: 15px 0;
  }

  &__status{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &-block{
      width: 33.3%;
      text-align: center;
      padding: 5px;
      overflow: hidden;
    }
  }

  &__line{
    display: flex;
    flex-flow: wrap row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px #e4e4e4 dashed;
    padding: 10px;

    &-title{
      width: 40%;
      line-height: 1.3;
      font-size: 12px;

      & a{
        font-size: 14px;
        color: #333;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
      }

      &-btn{

      }
    }
    &-quantity,
    &-price{
      width: 20%;
      text-align: center;
      font-size: 12px;
    }

    &-modify{
      font-size: 14px;
    }
  }

}
@media (min-width: 600px) {
  .order {
    &__item{
      padding: 10px 0px 10px;
      &-block {

        &-left,
        &-right {
          width: 50%;
        }
      }
    }
  }
}

@media (min-width: 1240px) {
.order{
  &__item{
    &-block{
      &-left,
      &-right {
        width: 50%;
      }
    }
  }

  &__line{
    &-title {
      & a {
        &:hover {
          color: #ff0000;
        }
      }
    }
  }
}
}