.brands{
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  color: #333;
  margin: 0 50px;

  &__modify{
    margin-top: 30px;
  }

  &__panel{

    & strong{
      margin-right: 20px;
    }

    & a{
      color: #333;;
      margin-right: 10px;
    }
  }

  &__title{
    font-size: 26px;
    margin-top: 15px;
    border-bottom: 1px #8a8a8a solid;
  }

  &__row{
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-start;
    margin-top: 5px;

    & a{
      color: #333;
      margin: 0 10px;
      line-height: 2;
    }
  }

  &__filter {
    height: 160px;
    flex-direction: column;
    justify-content: space-around;

    &-sort{
      text-align: center;

      &-title{
        margin-bottom: 7px;
        display: block;
      }
    }
  }

  &__side{
    display: block;
    width: 90%;
    margin: 0 auto 20px;
  }

  &__category{
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media (min-width: $tablet-portrait) {
  .brands {
    &__filter {
      height: 40px;
      flex-direction: row;
      justify-content: space-around;

      &-sort {
        text-align: left;

        &-title {
          margin-bottom: 0;
          display: inline;
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .brands {

    &__panel {
      & a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__row{

      & a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__side{
      width: 240px;
      margin: 0 0 0 30px;
    }

    &__category{
      flex-direction: row;
      justify-content: space-between;
    }

    &__filter {
      height: 160px;
      flex-direction: column;
      justify-content: space-around;

      &-sort{
        text-align: center;

        &-title{
          margin-bottom: 7px;
          display: block;
        }
      }
    }
  }
}