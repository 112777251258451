.featured{
  font-family: "ProximaNova", sans-serif;

  &__product{
    display: none;
  }
  &__title{
    font-size: 18px;
    color: #cfcfcf;
    font-weight: normal;
    text-align: center;
    margin: 15px;
  }

  &__item{
    width: 300px;
    background-color: #1e1e1e;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 1px rgba(207,207,207,0.08) solid;
    margin: 0 auto 15px;

    &-title{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #cfcfcf;
      padding: 15px;
      font-size: 14px;

      & a{
        color: #cfcfcf;
      }
    }

    &-wrapper{
      width: 300px;
      padding: 0  0 15px 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-image{
      width: 155px;
      height: 106px;
      text-align:center;

      & img{
        width: auto;
        height: 100%;
      }
    }

    &-info{
      width: 115px;
      position: relative;
    }

    &-price{
      height: 40px;
      width: 115px;
      color: #cfcfcf;
      -webkit-border-radius: 4px 0 0 4px;
      -moz-border-radius: 4px 0 0 4px;
      border-radius: 4px 0 0 4px;
      background: rgb(59,2,2); /* Old browsers */
      background: -moz-linear-gradient(45deg, rgb(255, 0, 0) 0%, rgb(59, 2, 2) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, rgb(255, 0, 0) 0%, rgb(59, 2, 2) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, rgb(255, 0, 0) 0%, rgb(59, 2, 2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b0202', endColorstr='#ff0000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 1.419;
      //margin-top: 16px;
    }

    &-rating{
      display: block;
      margin-bottom: 8px;
      text-align: center;
    }

    &-buttons{
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      bottom: 6px;
      width: 80px;
      margin: 0 auto;
      left: 0;
      right: 0;

      &-basket{
        width: 30px !important;
      }
    }
  }
}
@media (min-width: 900px) {
  .featured {

    &__product {
      display: block;
    }

    &__item {
      &-title {
        & a {

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}