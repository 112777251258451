.connectivity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 30px;
  font-size: 12px;
  padding-left: 15px;

  &__wrapper{
    width: 100%;
    margin: 0 auto;
    text-align: right;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;

    &::before {
      content: '';
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url(../images/icons/functional/icons-connectivity.svg);
      background-size: 30px 30px;
      top: 10px;
    }
  }


  & span {
    color: #fff;
    display: block;
    margin: 0 10px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
    font-family: Verdana, sans-serif;

    & button{
      float: right;
      position: relative;
      color: #fff;
      display: block;
      font-family: Verdana, sans-serif;
      font-size: 13px;
      font-weight: normal;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
      background: none;
      border: none;
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media (min-width: $tablet-portrait) {
  .connectivity {
    font-size: 14px;

    &__wrapper{

      &::before {
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
        top: 0;
      }
    }
  }
}
@media (min-width: $desktop) {
.connectivity{

  & span{

    & button{
      display: inline;
    }


    & button::after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      width: 0;
      background-color: #ece8e8;
      -webkit-transition: width .2s ease, left .2s ease;
      transition: width .2s ease, left .2s ease;
      left: 50%;
      bottom: -3px;
    }

    & button:hover::after {
      width: 100%;
      left: 0;
    }

    & button:hover,
    & button.visited:hover {
      color: #fff;
    }
  }
}
}