.social__popup{
  position: relative;
  width: 1500px;
  height: 1030px;
  color: #333;
  background: #fff;
  padding: 0;

  .page__product__wrapper-modify{
    top: 50px !important;
  }
}

@media (min-width: 500px){
  .social__popup{
    min-height: 400px;

  }
}
@media (min-width: $tablet-portrait) {
  .social__popup {
    margin: 50px auto;
    box-shadow: 0px 0px 5px 0 rgba(200,200,200,0.8);
    border: 1px #e4e4e4 solid;


  }
}
@media (min-width: $tablet-landscape) {

}
@media (min-width: $desktop) {
  .social__popup{
    min-height: 500px;
  }
}