.checkout-v2{
  color: #333;
  font-family: "Open Sans", sans-serif;

  &__wrapper {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__left {
      width: 100%;

      &-btns{
        border-bottom: 1px #e4e4e4 dashed;
        padding: 15px 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      &-btn{
        font-family: Verdana, sans-serif;
        font-size: 14px;
        width: 140px;
        max-width: 180px;
        background: none;
        color: #333;
        font-weight: normal;
        text-align: center;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        line-height: 1;

        &-active{
          color: #333;
        }

        &:hover{
          color: #ff0000;
        }
      }
    }

    &__right {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
    }
  }

  &__login{

    &-go{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &-link{
      position: relative;
      top: 10px;
      float: right;
      width: 140px;
      max-width: 180px;
      text-align: center;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;

      & a{
        color: #333;
        padding: 5px 5px;
        border-bottom: 1px #8a8a8a dashed;

        &:hover{
          color: #ff0000;
        }
      }
    }

    &-btn{
      width: 100%;
      margin: 15px 0 0 !important;
      display: inline;
    }
  }

  &__blocktitle{
      font-size: 18px;
      font-family: "Open Sans", sans-serif;
      border-bottom: 1px #e4e4e4 solid;
      padding: 0 10px 10px;

    &-down{
      margin-top: 30px;
    }
  }

  &__dataline {
    margin-top: 20px;

    &-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__inputblock{
      width: 71%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
    }

    &-error{
      display: block;
      font-size: 12px;
      padding-top: 10px;
      color: red;
      text-align: center;
    }

    &-title{
      color: #333;
      padding-right: 10px;
      font-size: 14px;
    }

    &-input {
      background: none;
      border: none;
      border-bottom: 1px #e4e4e4 dashed;
      color: #333;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px !important;
      padding: 6px 8px !important;
      width: 100%;
    }

    &-check{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      & label{
        font-size: 12px;
      }

      & input{
        margin-right: 5px;
      }
    }

    &-radio{
      margin: 15px 15px 0;
      display: block;
      width: 100%;

      & label{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;

        & input{
          margin-right: 5px;
        }
      }
    }

    &-input-login {
      border: none;
      border-bottom: 1px #e4e4e4 solid;
      width: 78%
    }

    &-list {
      background: none;
      border: none;
      border-bottom: 1px #e4e4e4 dashed;
      color: #333;
      width: 100%;
      padding: 5px;
      /*for WebKit*/
      -webkit-appearance: none;
      /* for FF */
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: '';
      /* for IE */
      -ms-appearance: none;
      appearance: none !important;
      background-image: url('../images/select-arrow.png');
      background-size: 11px;
      background-position: 98% center;
      background-repeat: no-repeat;

      & option{
        color: #333;
        background-color: #fff;
        border: 1px rgba(207,207,207,0.08) solid;
        border-radius: 4px;
      }
    }
  }
  #payment-method{
    width: 100%;
    margin-bottom: 10px;
  }
  &__shipping,
  &__pay{
    width: 100%;
    height: auto;

    &-title{
      margin-top: 20px !important;
    }
  }

  &__order{
    width: 100%;
    margin-top: 31px;

    &-comment-field{
      margin-bottom: 20px;
    }

    &-panel{
      border-bottom: 1px #e4e4e4 solid;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
    }

    &-title{
      width: 100%;
      padding-bottom: 10px;
      text-align: center;

      & a {
        color: #333;
        display: inline-block;
        position: relative;
        line-height: 1.5;
        transition: color .3s ease;

        &:hover{
          color: #ff0000;
        }
      }
    }

    &-quantity,
    &-price{
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      &-block{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
      }

      & button{
        background: none;
        border: none;
        color: #333;
        border: 0;
        padding: 0;
      }

      & input{
        color: #333;
        width: 40px;
        border: 1px #e4e4e4 solid;
        font-size: 14px;
        padding: 5px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        text-align: center;
      }
    }

    &-products{
      width: 100%;
    }

    &-product{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      padding: 15px;
      border-bottom: 1px #e4e4e4 dashed;
      line-height: 1.3;
      font-size: 14px;

      &:last-child {
        border-bottom: 1px #e4e4e4 solid !important;
      }
    }

    &-bottom{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      font-size: 14px;

      &-privilege{
        width: 100%;

        &-line{
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 0 auto 15px;
        }

        &-field{
          width: 100%;
          border: none;
          border-bottom: 1px #e4e4e4 solid;
          color: #333;
          height: 30px;
          padding: 6px 8px !important;
        }

        &-btn{
          font-family: Verdana, sans-serif;
          font-size: 14px;
          width: 140px;
          max-width: 180px;
          background: none;
          border: 1px #e4e4e4 solid;
          -moz-border-radius: 4px;
          -webkit-border-radius: 4px;
          border-radius: 4px;
          color: #333;
          padding: 8px 5px;
          font-weight: normal;
          text-align: center;
          -webkit-transition: all .3s ease;
          transition: all .3s ease;
          line-height: 1;
          margin: 0 20px;

          &:hover{
            border-color: #e4e4e4;
            color: #ff0000;
          }
        }
      }

      &-total {
        width: 270px;
        margin: 20px 0 10px;

        & span {
          display: block;
          margin-bottom: 10px;
          text-align: right;
        }
      }
    }

    &-comment{
      width: 100%;
      font-size: 14px;
      margin-top: 10px;

      &-title{
        color: #333;
        font-size: 14px;
        margin: 10px 0 5px;
      }

      &-field{
        color: #333;
        border: 1px #e4e4e4 solid;
        width: 100%;
        margin: 15px 0 10px;
        font-size: 14px;
        padding: 10px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
      }
    }

    &-confirm{
      border-top: 1px #e4e4e4 solid;
      margin-top: 10px;
      padding-top: 20px;
      width: 100%;

      & span{
        display: block;
        text-align: center;
        font-size: 14px;
      }

      &-btn{
        margin: 20px auto 0;
        display: block;
      }
    }
  }

  .dashicons-checkout{
    margin-right: 5px;
  }

  .dashicons-checkout1{
    width: 20px;
    height: 20px;
    font-size: 28px;
    margin-left: 5px;

    &:before{
      position: relative;
      top: -4px;
      left: -4px;
    }
  }

  & ul li{
    margin: 0 !important;
  }
  &__modallist{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.5);
    margin: 0 !important;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 !important;
    position: absolute !important;
    z-index: 1000;
    text-indent: 0 !important;

    & li{
      list-style-type: none;
      padding: 5px !important;
      text-align: left;
    }

    & li:hover{
      background-color: #efefef;
    }

    & li a{
      color: #333;
      padding: 7px;
      text-decoration: none;
      display: block;
    }
  }
}

.hide{
  display: none;
}

.quickcheckout-content{
  display: flex;
  flex-flow: wrap;
  width: 100%;
}
@media (min-width: $phone-landscape) {

  .checkout-v2 {
    &__wrapper {
      padding: 0 30px;
    }

    &__dataline {

      &__inputblock{
        width: 100%;
      }

      &-input,
      &-list{
        width: 100%;
      }
    }

    &__order {
      &-panel {
        flex-direction: row;
      }

      &-product{
        flex-direction: row;
      }

      &-title {
        width: 40%;
        padding-bottom: 0;
      }

      &-quantity{
        width: 20%;
        text-align: center;
      }
      &-price{
        width: 20%;
        text-align: center;
      }

    }
  }
}

@media (min-width: 500px) {
.checkout-v2{
    &__wrapper {

      &__right {
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }
    &__shipping,
    &__pay {
      width: 100%;

    }
  }
}

@media (min-width: $tablet-portrait){

  .checkout-v2 {

    &__order{
      &-comment {
        margin-top: 0px;
      }
    }

    &__dataline {

      &-wrapper{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-title{
        margin-bottom: 10px;
      }
      &-input,
      &-list{
        width: 100%;
      }
    }

    &__wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      &__left {
        width: 34.9%;
        padding: 0 15px 0 0;
      }

      &__right {
        width: 64.9%;
        padding: 0 0 0 15px;
      }
    }

    &__order{

      &-bottom {
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 30px;
        font-size: 14px;

        &-privilege{
          width: 70%;
          margin-left: 20px;
        }

        &-total{
          margin: 0;
        }
      }
    }
  }
}
@media (min-width: 860px) {
  .checkout-v2 {

    &__dataline {

      &-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &-title{
        margin-bottom: 0;
      }

      &__inputblock{
        width: 71%;
      }

      &-input,
      &-list{
        width: 100%;
      }
    }

  }
}
@media (min-width: $tablet-landscape) {
  .checkout-v2 {

    &__wrapper {

      &__left {
        width: 29.9%;
      }

      &__right {
        width: 69.9%;
      }

    }
    &__pay,
    .checkout-v2__order-comment{
      width: 50%;
    }
  }
}
@media (min-width: $desktop) {
  .dashicons-checkout{
    width: 22px !important;
    height: 22px !important;
    font-size: 14px !important;
    margin-right: 0 !important;
  }
  .dashicons-checkout1 {
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;
    margin-left: 0 !important;

    &:before {
      position: relative;
      top: 0 !important;
      left: 0 !important;
    }
  }

  .checkout-v2 {

    &__wrapper{
      padding: 0;

    }
    #payment-method{
      width: 66%;
    }
    &__shipping{
      width: 33%;
      &-title{
        margin-top: 0 !important;
      }
    }
    &__pay{
      width: 38%;
      max-width: 385px;

      &-title{
        margin-top: 0;
      }
    }
    .checkout-v2__order-comment{
      width: 60%;
      margin-right: 10px;

      &-title{
        margin-top: 0;
      }
    }

    &__dataline {

      &__inputblock{
        width: 75%;
      }

      &-input,
      &-list {
        width: 100%;
      }
    }

    &__order{
      &-quantity{
        &-block{
          margin-top: 5px !important;
        }
      }
    }
  }
}

@media (min-width: $bigdesktop) {
.checkout-v2{
  &__wrapper{
    padding: 0 30px;
  }
  &__order{
    &-comment,
    .checkout-v2__pay,
    .checkout-v2__shipping{
      width: 50%;
      font-size: 14px;
      margin-top: 0px;
    }
  }
}
  #payment-method{
    width: 66%;
  }
}