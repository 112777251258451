.baskett{
  position: relative;
  min-height: 100vh;
  min-width: 300px;
  max-width: 700px;
  color: #333;
  background: #fff;
  padding: 30px 20px;

  &__title{
    font-size: 20px;
    font-family: ProximaNova, sans-serif;
  }

  &__product {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border-bottom: 1px #e4e4e4 solid;
    padding: 10px;
    margin-bottom: 20px;

    &-image{
      height: 110px;
      width: 170px;
      margin: 10px 0;
      text-align: center;

    & img {
      height: 100%;
      width: auto;
    }
    }
    &-content{
      width: 100%;
    }

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1.2;
      margin: 0 10px;
      text-align: center;

      & a {
        font-size: 14px;
        color: #333;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
      }
    }

    &-panel{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin: 25px 0 0;
      text-align: center;

      &-delete{
        position: relative;
        width: 24px;
        height: 24px;
        padding: 13px 0 13px 0;
        background: none;
        border: none;
        -webkit-transition: opacity .3s ease;
        transition: opacity .3s ease;

        span {
          display: block;
          width: 100%;
          height: 1px;
          background-color: transparent;

          &:before,
          &:after{
            position: absolute;
            left: 0;
            right: 0;
            height: inherit;
            background-color: inherit;
            content: '';
          }

          &:before {
            top: 12px;
            background-color: #333;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }

          &:after {
            bottom: 13px;
            background-color: #333;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
        }
      }

    }
  }

  &__footer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-summa{
      line-height: 1;
      text-align: center;
      margin-bottom: 10px;

      &:last-child{
        margin-right: 0;
      }
    }

    &-btn{
      width: auto !important;
      margin-top: 10px !important;
    }


  }

  &__empty{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }
}

@media (min-width: 500px){
  .baskett{
    min-height: 400px;

    &__product{

      &-content{
        width: 50%;
      }

      &-title{
        text-align: left;
      }
    }
  }
}
@media (min-width: $tablet-portrait) {
  .baskett {
    margin: 50px auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0 rgba(200,200,200,0.8);
    border: 1px #e4e4e4 solid;
    padding: 30px 50px;

    &__product {

      &-title a:hover{
        color: #ff0000;
      }

      &-content{
        width: 65%;
      }

    }

  }
}
@media (min-width: $tablet-landscape) {

}
@media (min-width: $desktop) {
.baskett{
  min-height: 500px;
}
}