.site{

  &__background-home,
  &__background-main{
        background-color: $background-main;
        opacity: 0;
        transition: opacity .2s ease-in;
  }

  &__wrapper{
        position: relative;
        //z-index: 1;
  }

  &__success{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: auto;
    width: 95%;
    margin: 0 auto;

      &-wrapper {
        width: 100%;
        display: block;
        padding: 30px 0 0;

        & span{
          text-align: center;
          display: block;
          color: #fff;
          font-size: 30px;
          margin-bottom: 10px;
          text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
          font-family: Century Gothic, sans-serif;

          & i{
            font-style: normal;
          }
        }
      }
    }

  &__info{
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;

      & p{
        color: #333;
        font-size: 14px;
        line-height: 1.6;
        margin-bottom: 20px;
        text-indent: 20px;
      }
    }

  &__brands{
    display: none;
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  }
}

@media (min-width: 600px){
  .site {
    &__success {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      min-height: 430px;

      &-wrapper {
          padding: 136px 0 0;

        & span {
          font-size: 30px;
        }
      }
    }
  }
}

@media (min-width: 700px) {
  .site {
    &__success {

      &-wrapper {
        padding: 121px 0 0;

        & span {
          font-size: 40px;
        }
      }
    }
  }
}

@media (min-width: 800px) {
  .site {
    &__success {
      &-wrapper {
        padding: 134px 0 0;
      }
    }
  }
}

@media (min-width: 865px){
  .site{

    &__brands{
      display: block;
    }
  }
}


@media (min-width: 900px) {
  .site {
    &__success {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      //min-width: 1150px;
      max-width: 1725px;
      margin: 0 auto 90px;
      height: calc(100vh - 400px);


      &-wrapper {
        display: block;
        margin: 0 auto;
        padding: 0;
        & span{
          font-size: 50px;
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .site{
    &__brands{
        min-width: 1150px;
        width: 100%;
        max-width: 1725px;
        padding-top: 0;
        padding-bottom: 20px;
      }

      &__info{
        width: 95%;
        max-width: 1725px !important;
        padding-top: 40px;

      }

    }
}

@media (min-width: 1910px) {
  .site {
    &__success {
      &-wrapper {
        & span{
          font-size: 70px;
        }
      }
    }
  }
}